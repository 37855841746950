var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.itemData && _vm.itemData.id ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__image"
  }, [_vm.itemData.icon ? [_c('img', {
    attrs: {
      "src": _vm.itemData.icon
    }
  })] : [_vm.itemData.type == 'discord' ? _c('svg-icon', {
    attrs: {
      "name": "icon-discord"
    }
  }) : _vm._e(), _vm.itemData.type == 'telegram' || _vm.itemData.type == 'channel' ? _c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  }) : _vm._e(), _vm.itemData.type == 'twitter' ? _c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  }) : _vm._e()]], 2), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" " + _vm._s(_vm.itemData.title) + " ")]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.onClick
    }
  }, [_vm.itemData.type == 'telegram' ? [_vm._v(_vm._s(_vm.$lang('Join')))] : _vm.itemData.type == 'channel' ? [_vm._v(_vm._s(_vm.$lang('Subscribe')))] : _vm.itemData.type == 'twitter' ? [_vm._v(_vm._s(_vm.$lang('Follow')))] : (_vm.itemData.type || '') == 'advPlatForm' || (_vm.itemData.provider || '') == 'advPlatForm' ? [_vm._v(_vm._s(_vm.$lang('Watch')))] : [_vm._v(_vm._s(_vm.$lang('Open')))]], 2)]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.itemData.reward)) + " "), _vm.itemData.reward_stock > 0 ? _c('div', {
    staticClass: "invite__keys"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/earn-key.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.itemData.reward_stock) + " ")]) : _vm._e()]), (_vm.itemData.type || '') !== 'advPlatForm' && (_vm.itemData.provider || '') !== 'advPlatForm' ? _c('div', {
    staticClass: "btn-submit",
    class: [_vm.isCheck && 'refresh dis', _vm.isClick ? '' : 'dis'],
    on: {
      "click": function ($event) {
        _vm.isClick && _vm.onCheck();
      }
    }
  }, [_vm.isCheck ? [_vm._v(" " + _vm._s(_vm.$lang("Checking")) + " "), _c('svg-icon', {
    attrs: {
      "name": "icon-refresh"
    }
  })] : [_vm._v(" " + _vm._s(_vm.$lang("Check")) + " ")]], 2) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };