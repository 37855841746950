import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    lotterylist: [],
    lotteryDetail:{}
  },
  mutations: {
    setLotteryList: (state, payload) => {
      state.lotterylist = payload;
    },
    setLotteryDetail: (state, payload) => {
      state.lotteryDetail = payload;
    },
  },
  actions: {
    getLotteryList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/lottery/list",{});
        vailcode(rs, () => {
          commit("setLotteryList", rs.data);
        });
    },
    getLotteryDetail:async ({ state, commit },id) => {
      const rs = await Vue.prototype.$http.post("/lottery/detail",{id});
      vailcode(rs, () => {
        commit("setLotteryDetail", rs.data);
      });
  },
  },
  getters: {},
};
