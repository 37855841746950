var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "80%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop__intro"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-cancel",
    on: {
      "click": _vm.cancelFn
    }
  }, [_vm._v(_vm._s(_vm.$lang("Cancel")))]), _c('div', {
    staticClass: "btn btn-confirm",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(_vm._s(_vm.$lang("Leave")))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };