const debounce = {
   inserted: function (el, binding) {
      console.log(binding);
      let timer
      el.addEventListener('click', () => {
         if (timer) {
            clearTimeout(timer)
         }
         timer = setTimeout(() => {
            binding.value()
         }, 1000)
      })
   },
}

export default debounce