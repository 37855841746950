var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "85%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "award-img",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/pop-award-icon-0.png`)})`
    }
  }), _c('div', {
    staticClass: "award-info"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Yes fren!"))), _c('br'), _vm._v(" " + _vm._s(_vm.$lang("You get an award!"))), _c('br')]), _c('div', {
    staticClass: "award-btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    }
  }, [_vm._v(_vm._s(_vm.$lang("Play Yescoin")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };