import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import cookie from "js-cookie";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {};
  },
  props: {},
  watch: {
    "$parent.popAdvTipShow": {
      handler(newVal, oldVal) {
        if (!newVal && typeof oldVal !== 'undefined') {
          this.$parent.popAdvTipShow = false;
          cookie.set("advTip:" + this.userData.id + ":" + this.setting.popadv.id, true, {
            expires: 1 / this.setting.popadv.pop_times
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async onSubmit() {
      this.$parent.popAdvTipShow = false;
      this.$nextTick(() => {
        if (this.setting.popadv.type == 'page') this.$router.push(this.setting.popadv.url);else if (this.setting.popadv.type == 'telegram') this.WebApp.openTelegramLink(this.setting.popadv.url);else if (this.setting.popadv.type == 'link') this.WebApp.openLink(this.setting.popadv.url);
      });
    }
  }
};