import "core-js/modules/es.array.unshift.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const mineVuex = createNamespacedHelpers("mine");
const airdropVuex = createNamespacedHelpers("airdrop");
import vailcode from "@utils/errcode";
export default {
  name: "airdropTap",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...mineVuex.mapState(["hourEearnings"]),
    ...airdropVuex.mapState(["airdropTapInfo"])
  },
  data() {
    return {
      progressList: [{
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }],
      isProgOk: false,
      countDown: 0,
      dateInfos: [{
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }],
      isFlag: true,
      popAirdropTapTipShow: false,
      popAirdropTapLogsShow: false,
      tipData: {
        icon: "",
        title: "",
        brief: "",
        btns: []
      },
      countDownTime: null
    };
  },
  beforeRouteLeave(to, from, next) {
    this.isFlag = true;
    this.isProgOk = false;
    this.popAirdropTapTipShow = false;
    this.popAirdropTapLogsShow = false;
    this.clearIntervalFn();
    clearTimeout(this.countDownTime);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "isProgOk": {
      handler() {
        if (this.isProgOk) {
          this.formatDateInfos();
        }
      }
    },
    "airdropTapInfo": {
      handler() {
        this.doData();
      }
    }
  },
  async mounted() {
    if (!this.airdropTapInfo) {
      await this.getAirdropTapInfo();
    } else {
      this.doData();
    }
  },
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    ...airdropVuex.mapMutations(['setAirdropTapInfo']),
    ...airdropVuex.mapActions(["getAirdropTapInfo"]),
    doData() {
      this.countDown = this.airdropTapInfo.countdown || 0;
      this.countDownLeft();
      if (this.airdropTapInfo.enabled && !this.airdropTapInfo.unclaimedAmount) {
        this.progressList.map(item => {
          item.isOk = true;
          item.progress = 100;
          return item;
        });
      }
      this.animationFn();
    },
    formatDateInfos() {
      if (this.airdropTapInfo.dateInfos && this.airdropTapInfo.dateInfos.length < 7) {
        let len = this.airdropTapInfo.dateInfos.length;
        for (let i = 0; i < 7 - len; i++) {
          this.airdropTapInfo.dateInfos.unshift({
            date: "",
            claimed: false
          });
        }
      }
      this.dateInfos.map((item, index) => {
        if (this.airdropTapInfo && this.airdropTapInfo.dateInfos[index]) {
          item = this.airdropTapInfo.dateInfos[index];
          this.dateInfos[index] = this.airdropTapInfo.dateInfos[index];
        }
        return item;
      });
      console.log('this.dateInfos', this.dateInfos, this.airdropTapInfo.dateInfos);
    },
    clearIntervalFn() {
      console.log("我将准备清除所有定时器");
      for (let i = 1; i < 30000; i++) {
        clearInterval(i);
      }
    },
    animationFn() {
      let progressTimer = null;
      const fn = (index, callback) => {
        if (this.progressList[index].progress + 2 <= 100) this.progressList[index].progress += 2;
        if (this.progressList[index].progress >= 100) {
          this.progressList[index].isOk = true;
          if (index === 5) {
            //this.clearIntervalFn();
            clearInterval(progressTimer);
            this.isProgOk = true;
          }
        }
        if (this.progressList[index].progress >= 100) {
          typeof callback == "function" && callback();
        }
      };
      let timer = setTimeout(() => {
        clearTimeout(timer);
      }, 10);
      progressTimer = setInterval(() => {
        fn(0, () => {
          fn(1, () => {
            fn(2, () => {
              fn(3, () => {
                fn(4, () => {
                  fn(5, () => {});
                });
              });
            });
          });
        });
      }, 10);
    },
    async onClaim() {
      if (!this.isFlag) return;
      this.$loading.show();
      this.isFlag = false;
      let rs = await this.$http.post("/airdrop/tap/claim", {});
      this.$loading.hide();
      vailcode(rs, () => {
        this.setAirdropTapInfo(rs.data.airdropInfo);
        this.countDown = rs.data.countdown;
        this.countDownLeft();
        this.formatDateInfos();
        this.isFlag = true;
      }, () => {
        this.isFlag = true;
      });
    },
    countDownLeft() {
      clearTimeout(this.countDownTime);
      if (this.countDown > 0) {
        this.countDownTime = setTimeout(() => {
          this.countDown = this.countDown - 1;
          console.log('this.countDown', this.countDown);
          this.countDownLeft();
        }, 1000);
      }
    },
    onTip(t) {
      switch (t) {
        case 0:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon1.png`);
          this.tipData.title = 'Profit per hour';
          this.tipData.brief = 'Those who love learning will be rewarded more, and Profit per hour is an important criterion.';
          this.tipData.btns = [{
            "name": "More",
            "route": '/mine'
          }];
          break;
        case 1:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon2.png`);
          this.tipData.title = 'Keys';
          this.tipData.brief = 'Participate in in-game tasks, which not only allows you to learn more about the encryption field, but also earn more rewards. The number of keys is an important metric.';
          this.tipData.btns = [{
            "name": "More",
            "route": '/earn'
          }];
          break;
        case 2:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon3.png`);
          this.tipData.title = 'Friends';
          this.tipData.brief = 'Groups of people are participating in us. Let more of your friends participate, learn together, grow together, and get rewards together.';
          this.tipData.btns = [{
            "name": "Invite friends",
            "route": '/frens'
          }];
          break;
        case 3:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon4.png`);
          this.tipData.title = 'Invitation Bonus';
          this.tipData.brief = 'Your friends get profits, and you can also get a part of the profits. The more friends participate, the more you can get.';
          this.tipData.btns = [{
            "name": "Invite friends",
            "route": '/frens'
          }];
          break;
        case 4:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon5.png`);
          this.tipData.title = 'Game Activity';
          this.tipData.brief = 'Actively participate in the game, including learning, tasks, inviting friends, airdrops, the more active you are, the more rewards you will get.';
          this.tipData.btns = [{
            "name": "I understand",
            "route": ""
          }];
          break;
        case 5:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon6.png`);
          this.tipData.title = 'Official media';
          this.tipData.brief = 'The news from official media is worth paying attention to and interacting with. Not only can you get the information first, but you can also get more rewards.';
          this.tipData.btns = [{
            "name": "Channels",
            "route": this.setting.socials.channel
          }, {
            "name": "X",
            "route": this.setting.socials.twitter
          }];
          break;
      }
      this.popAirdropTapTipShow = true;
    },
    onDetails() {
      this.popAirdropTapLogsShow = true;
    }
  }
};