import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    categoryList: [],
    taskList:{},
    earnings:{},
    specialsMine:{},
    selectTab:0,
    specialsMineInfo:{
      coin:0,
      totalCoin:0,
      list:[]
    },
    hourEearnings:0,
    totalHourEearnings:0,
    lastUpgradeTime:0
  },
  mutations: {
    setSelectTab: (state, payload) => {
      state.selectTab = payload;
    },
    setCategoryList: (state, payload) => {
      state.categoryList = payload;
    },
    setTaskList: (state, payload) => {
      state.taskList[payload.type] = payload.list;
      console.log("state.taskList",payload)
    },
    setEarnings: (state, payload) => {
      state.earnings = payload;
    },
    setHourEarnings: (state, payload) => {
      state.hourEearnings = payload;
    },
    setTotalHourEarnings: (state, payload) => {
      state.totalHourEearnings = payload;
    },
    updateTask:(state, payload) =>{
      state.taskList.forEach((item,index) => {
        if(item.id == payload.id){
          state.taskList[index] = payload;
        }
      });
    },
    setSpecialsMine: (state, payload) => {
      state.specialsMine = payload;
    },
    setSpecialsMineInfo: (state, payload) => {
      state.specialsMineInfo = payload;
    },
    setLastUpgradeTime: (state, payload) => {
      state.lastUpgradeTime = payload;
    },
  },
  actions: {
    getCategoryList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/mine/category/list",{});
        vailcode(rs, () => {
          commit("setCategoryList", rs.data);
        });
    },
    getTaskList:async ({ state, commit },[categoryId,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/mine/task/list",{"categoryId":categoryId});
      vailcode(rs, () => {
        rs.data.forEach((item,index)=>{
          if(item.dependency_tip.indexOf("[stock.ico]") !== -1){
            item.show_key = true
            item.dependency_tip = item.dependency_tip.replace("[stock.ico]",'')
          }
          else{
            item.show_key = false
          }
        })
        commit("setTaskList", {type:categoryId,list:rs.data});
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    mineUpgrade:async ({ state, commit },[taskId,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/mine/upgrade",{"taskId":taskId});
      vailcode(
        rs,
        () => {
          commit("setEarnings",rs.data.mine)
          commit("setHourEarnings",rs.data.mine.hour_earnings)
          //commit("updateTask",rs.data.task_info)
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    mine:async ({ state, commit }) => {
      const rs = await Vue.prototype.$http.post("/mine/mine",{});
      vailcode(
        rs,
        () => {
          commit("setEarnings",rs.data)
          commit("setHourEarnings",rs.data.hour_earnings)
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    getHourEarnings:async ({ state, commit }) => {
      const rs = await Vue.prototype.$http.post("/mine/earnings",{});
      vailcode(
        rs,
        () => {
          commit("setHourEarnings",rs.data)
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    getTotalHourEarnings:async ({ state, commit }) =>{
      const rs = await Vue.prototype.$http.post("/mine/totalearnings",{});
      vailcode(
        rs,
        () => {
          commit("setTotalHourEarnings",rs.data)
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    getSpecialsMine:async ({ state, commit }) => {
      const rs = await Vue.prototype.$http.post("/mine/lucky",{});
      vailcode(rs, () => {
        commit("setSpecialsMine", rs.data);
      });
    },
    getEarningsIncrement:async({state,commit})=>{
      const rs = await Vue.prototype.$http.post("/mine/earnings/increment",{});
      vailcode(rs, () => {
        commit("setEarnings", rs.data);
      });
    }
  },
  getters: {},
};
