import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const assetsVuex = createNamespacedHelpers("assets");
import { toNano } from "@ton/core";
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "withdraw",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting", "tonConnect"]),
    ...userVuex.mapState(["userData", 'extraInvite']),
    ...assetsVuex.mapState(["assetsLog", "logHasMore", "logLoading", "logCondition", "assetsChainsList", "assetsList", "chainsInfo", "assetsInfo"])
  },
  data() {
    return {
      loadConfig,
      withdrawAmount: 0,
      scorllFlag: false,
      withdrawAddress: "",
      isFlag: true
    };
  },
  watch: {
    "tonConnect": {
      handler(bool) {
        if (bool && this.assetsInfo.chain == 'ton') {
          this.withdrawAddress = this.tonWallet.uiAddress;
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    if (this.assetsChainsList.length == 0) await this.getAssetsChains();
    if (this.assetsList.length == 0) await this.getAssetsList();
    let cid = this.$route.query.cid || 0;
    let id = this.$route.query.id || 0;
    if (!cid || !id) {
      this.$router.back();
      return;
    }
    if (!this.chainsInfo || !this.chainsInfo.id) {
      this.assetsChainsList.map(item => {
        if (item.id == cid) {
          this.setChainsInfo(item);
        }
      });
    }
    if (!this.assetsInfo || !this.assetsInfo.id) {
      this.assetsList.map(item => {
        if (item.id == id) {
          this.setAssetsInfo(item);
        }
      });
    }

    // if(!this.chainsInfo.id || !this.assetsInfo.id) {
    //   this.$router.back();
    //   return;
    // }
    setTimeout(() => {
      this.onLoadData(1);
      this.withdrawAmount = this.assetsInfo.balance;
      window.addEventListener('scroll', this.checkScroll);
      console.log("1111", this.tonConnect, this.chainsInfo);
      if (this.tonConnect && this.assetsInfo.chain == 'ton') {
        this.withdrawAddress = this.tonWallet.uiAddress;
      }
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    this.isFlag = true;
    window.removeEventListener('scroll', this.checkScroll);
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...assetsVuex.mapActions(["doWithdraw", "getAssetsInfo", "getAssetsLog", "getAssetsLogStatus", "getAssetsChains", "getAssetsList"]),
    ...assetsVuex.mapMutations(["setLogCondition", "setChainsInfo", "setAssetsInfo"]),
    ...userVuex.mapMutations(['setUserData']),
    async confirmFn() {
      if (!this.isFlag) return;
      if (!this.assetsInfo.withdraw_min_amount) {
        this.$toasted.error('Please enter withdrawal amount');
      }
      if (this.assetsInfo.balance < this.assetsInfo.withdraw_min_amount || this.withdrawAmount < this.assetsInfo.withdraw_min_amount) {
        return;
      }
      if (this.assetsInfo.balance < this.withdrawAmount) {
        this.$toasted.error("Insufficient balance");
        return;
      }
      this.isFlag = false;
      this.$nextTick(async () => {
        if (!this.withdrawAddress) {
          this.$toasted.error('Please enter withdrawal address');
          return false;
        }
        await this.doWithdraw([this.assetsInfo.chain, this.assetsInfo.symbol, this.withdrawAmount, this.withdrawAddress, async res => {
          if (res && res.raw) {
            let msg = [{
              address: res.contract,
              amount: toNano(res.amount).toString(),
              payload: res.raw
            }];
            this.tonWallet.sendTransaction(msg);
          }
          await this.getAssetsInfo([this.assetsInfo.symbol]);
          this.withdrawAmount = this.assetsInfo.balance;
          this.onLoadData(1);
        }]);
        this.isFlag = true;
      });
    },
    checkScroll() {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (!this.scorllFlag) {
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          // 接近底部时触发加载  
          this.scorllFlag = true;
          if (this.logHasMore && !this.logLoading) {
            let page = this.logCondition.page;
            this.onLoadData(page + 1);
          }
        }
      }
    },
    async onLoadData(page) {
      this.setLogCondition({
        "chain": this.assetsInfo.chain,
        "symbol": this.assetsInfo.symbol,
        "limit": 10,
        "page": page
      });
      await this.getAssetsLog([() => {
        this.scorllFlag = false;
      }, () => {
        this.scorllFlag = false;
      }]);
    },
    async checkOrderStatus(event, orderId) {
      const element = event.target;
      element.style.animation = 'refreshAmn 1s linear forwards';
      await this.getAssetsLogStatus([orderId, "withdraw", async () => {
        await this.getAssetsInfo([this.assetsInfo.symbol]);
        this.withdrawAmount = this.assetsInfo.balance;
        element.style.animation = ''; // 移除动画属性  
      }, () => {
        element.style.animation = ''; // 移除动画属性  
      }]);
    },

    async refresh() {
      const element = this.$refs.refRefresh;
      element.style.animation = 'refreshAmn 1s linear forwards';
      await this.getAssetsInfo([this.assetsInfo.symbol]);
      await this.onLoadData(1);
      element.style.animation = ''; // 移除动画属性  
      document.documentElement.scrollTo({
        top: 0,
        left: 0
      });
    },
    amountInput(val) {
      this.withdrawAmount = val.target.value.replace(/(\.\d\d)\d*/, '$1');
    },
    openView(item) {
      this.WebApp.openLink(this.chainsInfo.tx_view_url + item.tx_hash);
    },
    onTonConnect() {
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
      });
    }
  }
};