import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData", "loginReward"])
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    },
    "$parent.popLoginRewardShow": {
      async handler(bool) {
        if (!bool) {
          this.setLoginReward(0);
        }
      },
      immediate: true,
      deep: true
    }
  },
  props: {
    type: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    ...userVuex.mapMutations(["setLoginReward"])
  }
};