import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const assetsVuex = createNamespacedHelpers("assets");
const luckyVuex = createNamespacedHelpers("lucky");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "lucky",
  //转盘抽奖
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...assetsVuex.mapState(["assetsList"]),
    ...userVuex.mapState(["userData"]),
    ...luckyVuex.mapState(["luckyList", "luckyItems", "luckyLogs", "luckyLogHasMore", "luckyLoading", "luckyLogCondition"])
  },
  data() {
    return {
      prizeIndex: 0,
      prizeList: null,
      isPopResult: false,
      //奖品结果弹窗显示状态
      selectRow: null,
      //抽到的奖品
      isStartLucky: false,
      //是否开始抽奖
      isLoadingLuckyList: null,
      //转盘列表loading
      isFlag: true,
      //防止按钮重复点击
      coinResultRef: null,
      assetsInfo: {},
      luckyInfo: {},
      awardInfo: {},
      scorllFlag: false
    };
  },
  methods: {
    ...assetsVuex.mapActions(["getAssetsList"]),
    ...luckyVuex.mapActions(["getLuckyList", "getLuckyItems", "getLuckyLogs", "luckyDraw"]),
    ...luckyVuex.mapMutations(["setLuckyLogCondition", "setLuckyLogLoading"]),
    ...userVuex.mapMutations(['setUserData']),
    //开始抽奖
    onStart() {
      //校验资格

      //开始转盘

      if (!this.isFlag) return;
      if (this.luckyInfo.price_stock && this.luckyInfo.price_stock > this.userData.stock || this.luckyInfo.price_coin && this.luckyInfo.price_coin > this.userData.coin) {
        return false;
      }
      //扣除key

      // if(this.userData.coin < this.luckyInfo.price_coin || this.userData.stock < this.luckyInfo.price_stock){
      //   this.$toasted.error("Insufficient balance");
      //   retrun;
      // }
      this.isFlag = false; //防止重复提交
      const vueLuckywheel = this.$refs.vueLuckywheel;
      if (!vueLuckywheel) {
        this.isFlag = true;
        return;
      }
      if (!this.prizeList && this.prizeList.length > 0) {
        this.isFlag = true;
        return;
      }
      this.luckyDraw([this.luckyInfo.id, res => {
        this.isStartLucky = true;
        if (res.user) {
          if (res.lottery.price_coin > 0) {
            res.user.coin = res.user.coin - res.lottery.price_coin;
          }
          let newUserData = {
            ...this.userData,
            ...res.user
          };
          this.setUserData(newUserData);
        }
        //随机模拟中奖结果 随机数据索引
        //this.prizeIndex = Math.floor(Math.random() * this.prizeList.length);
        this.prizeList.forEach((item, index) => {
          if (item.id == res.winning_item.id) {
            this.awardInfo = res.winning_item;
            this.prizeIndex = index;
          }
        });
        this.isStartLucky = false;
        //执行转盘动画

        this.$nextTick(vueLuckywheel.draw);
      }, err => {
        this.isFlag = true;
      }]);
      //模拟接口请求
      // setTimeout(() => {

      // }, 300);
    },

    //中奖结果
    onResult() {
      //显示中奖结果弹窗
      this.selectRow = this.prizeList[this.prizeIndex];
      this.coinResultRef = this.$refs[`${this.selectRow.type}BalanceRef`];
      this.isPopResult = true;
      this.isStartLucky = false;
      document.body.scrollTo({
        top: 0,
        left: 0
      });
      this.onLoadData(1);
    },
    //外围灯光动画
    lightAnimFn() {
      this.$nextTick(() => {
        const luckyTableAdroinRef = this.$refs.luckyTableAdroinRef;
        if (!luckyTableAdroinRef) return;
        luckyTableAdroinRef.style.setProperty("--image1", `url(${require(`@images/${this.theme}/lucky/light1.png`)})`);
        luckyTableAdroinRef.style.setProperty("--image2", `url(${require(`@images/${this.theme}/lucky/light2.png`)})`);
      });
    },
    //更新数据
    onAccept() {
      let newUserData = {};
      if (this.awardInfo.currency == 'TAPCOINS') {
        newUserData.coin = this.userData.coin + this.awardInfo.amount;
        this.setUserData(newUserData);
      }
      if (this.awardInfo.currency == 'USDT') {
        this.assetsInfo.usdt = this.assetsInfo.usdt + this.awardInfo.amount;
      }
      //播放动画后 更新相关数据及UI
      //console.log("更新相关数据及UI");
      this.isFlag = true;
    },
    //获取转盘列表  不做配置就直接在data中写死
    async getLuckyListData() {
      this.isLoadingLuckyList = true;
      this.prizeList = [];
      await this.getLuckyItems(this.luckyLogCondition.lotteryId);
      this.luckyItems.forEach((item, index) => {
        this.prizeList.push({
          id: item.id,
          value: item.amount,
          unit: item.currency,
          type: item.currency.toLowerCase()
        });
      });
      this.isLoadingLuckyList = false;
    },
    onLoadData(page) {
      this.setLuckyLogCondition({
        lotteryId: this.luckyInfo.id,
        page: page,
        limit: 10
      });
      this.getLuckyLogs([() => {
        this.scorllFlag = false;
      }, () => {
        this.scorllFlag = false;
      }]);
    },
    checkScroll() {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (!this.scorllFlag) {
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          // 接近底部时触发加载  
          this.scorllFlag = true;
          if (this.luckyLogHasMore && !this.luckyLoading) {
            let page = this.luckyLogCondition.page;
            this.onLoadData(page + 1);
          }
        }
      }
    }
  },
  async mounted() {
    //if(this.assetsList.length == 0){
    await this.getAssetsList();
    //}
    this.assetsList.forEach(item => {
      this.$set(this.assetsInfo, item.name, item.balance);
    });
    await this.getLuckyList(['official', 0]);
    if (this.luckyList && this.luckyList.length > 0) {
      this.luckyInfo = this.luckyList[0];
      this.onLoadData(1);
      this.getLuckyListData();
    }
    this.lightAnimFn();
    window.addEventListener('scroll', this.checkScroll);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('scroll', this.checkScroll);
    this.$nextTick(() => {
      next();
    });
  }
};