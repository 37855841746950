import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import cookie from "js-cookie";
export default {
  name: "popAirdripTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"])
  },
  watch: {
    "$parent.popYoutubeShow": {
      handler(bool) {
        if (bool) {
          this.itemData = this.$parent.itemData;
          this.cacheKey = `YoutubeClick_${this.userData.id}_${this.itemData.id}}`;
          this.isWatch = cookie.get(this.cacheKey) ? true : false;
          let time = parseInt(new Date().getTime() / 1000);
          let tempTime = cookie.get(this.cacheKey);
          if (!tempTime) {
            this.leftTime = 0;
          } else {
            this.leftTime = time - Number(tempTime) < this.sTime ? time - Number(tempTime) : 0;
            this.countDown();
          }
          this.verificationCodes = "";
          this.isFlag = true;
          this.showTip = false;
        }
      }
    }
  },
  data() {
    return {
      itemData: null,
      timeVal: "",
      leftTime: 0,
      sTime: 60,
      verificationCodes: "",
      isWatch: false,
      isFlag: true,
      showTip: false,
      cacheKey: ""
    };
  },
  methods: {
    ...taskVuex.mapActions(["getSubTaskList", "taskClick", "doSubTask"]),
    ...userVuex.mapMutations(['setUserData']),
    handleInput(index, event) {
      const value = event.target.value;
      this.verificationCodes[index] = value;

      // 判断是否输入完成
      if (this.verificationCodes.join('').length === 6) {}

      // 自动跳到下一个输入框
      if (value && index < this.verificationCodes.length - 1) {
        const nextInput = event.target.nextElementSibling;
        if (nextInput) {
          this.$nextTick(() => {
            nextInput.focus();
          });
        }
      }
    },
    handleKeyDown(index, event) {
      // 处理删除操作
      if (event.key === 'Backspace' && !event.target.value && index > 0) {
        const prevInput = event.target.previousElementSibling;
        if (prevInput) {
          this.$nextTick(() => {
            prevInput.focus();
          });
        }
      }
    },
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('text');
      const codes = pastedText.trim().substring(0, 6).split('');
      this.verificationCodes = codes.concat(Array(6 - codes.length).fill(''));

      // nextTick 方法来确保在更新 DOM 之后设置焦点。我们通过 $refs.inputField 引用最后一个输入框，并使用 focus 方法将焦点设置在最后一个输入框中
      this.$nextTick(() => {
        const lastInput = this.$refs.inputFieldRef.value[this.verificationCodes.length - 1];
        if (lastInput) {
          lastInput.focus();
        }
      });
    },
    onClick() {
      this.WebApp.openLink(this.itemData.url);
      this.isWatch = true;
      let time = parseInt(new Date().getTime() / 1000);
      let tempTime = cookie.get(this.cacheKey);
      if (!tempTime) {
        cookie.set(this.cacheKey, time, {
          expires: 1
        });
        this.leftTime = this.sTime;
      } else {
        this.leftTime = time - Number(tempTime) < this.sTime ? time - Number(tempTime) : 0;
      }
      this.countDown();
      this.taskClick(this.itemData.id);
    },
    countDown() {
      clearTimeout(this.timeVal);
      this.leftTime--;
      if (this.leftTime > 0) {
        this.timeVal = setTimeout(() => {
          this.countDown();
        }, 1000);
      }
    },
    async onSubmit() {
      if (!this.isWatch) {
        return;
      }
      if (this.leftTime > 0) {
        this.showTip = true;
        return;
      }
      this.showTip = false;
      if (!this.isFlag) return;
      let vcode = this.verificationCodes; //.join("");
      if (vcode.length < 3) {
        this.$toasted.error(this.$lang('Luck code is error'));
        return false;
      }
      this.isFlag = false;
      await this.subTaskComplete(this.itemData, vcode);
      this.isFlag = true;
    },
    async subTaskComplete(item, value) {
      await this.doSubTask([item.id, "", value, data => {
        let newUserData = {
          ...this.userData,
          ...(data && data.userInfo ? data.userInfo : data)
        };
        item.completed = 1;
        this.$parent.itemData.completed = 1;
        if (item.cyclical == 1) {
          item.countdown = item.cycle_interval;
          this.$parent.itemData.countdown = item.cycle_interval;
        }
        this.setUserData(newUserData);
        this.$parent.popYoutubeShow = false;
        this.$parent.isPopRewardResultShow = true;
      }, rs => {
        this.error = rs && rs.message;
      }]);
    }
  }
};