import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const autobotVuex = createNamespacedHelpers("autobot");
import formatter from "@utils/formatter";
export default {
  name: "boost",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...autobotVuex.mapState(["botList"]),
    getLevelPrice() {
      return formatter.getLevelPrice;
    },
    toPre() {
      return formatter.toPre;
    }
  },
  data() {
    return {
      popType: "",
      popEnergyShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popEnergyShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  mounted() {
    console.log(this.setting);
    if (this.botList.length == 0) {
      if (this.setting.autoBotSetting && this.setting.autoBotSetting.length > 0) {
        this.setBotList(this.setting.autoBotSetting);
      } else {
        this.getBotList();
      }
    }
  },
  methods: {
    ...autobotVuex.mapMutations(["setBotList"]),
    ...autobotVuex.mapActions(["getBotList"]),
    onBoo(type) {
      this.popType = type;
      this.popEnergyShow = true;
    }
  }
};