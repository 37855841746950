import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const assetsVuex = createNamespacedHelpers("assets");
export default {
  name: "popSelectToken",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...assetsVuex.mapState(["assetsInfo", "assetsList"])
  },
  data() {
    const coins = [{
      icon: "https://static.tapcoins.app/icons/usdt.png",
      symbol: "USDT"
    }];
    return {
      coins
    };
  },
  watch: {
    "$parent.popSelectTokenShow": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getAssetsList();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...assetsVuex.mapMutations(["setAssetsInfo"]),
    ...assetsVuex.mapActions(["getAssetsList"]),
    onSelect(item) {
      this.setAssetsInfo(item);
      this.$parent.popSelectTokenShow = false;
    }
  }
};