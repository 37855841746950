var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "pph page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "pph-main"
  }, [_vm.theme != 'tapcoin' ? _c('div', {
    staticClass: "balance"
  }, [_c('div', {
    staticClass: "balance__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tap.png`)
    }
  })]), _c('div', {
    staticClass: "balance__num"
  }, [_vm._v(" 0 TAP "), _c('div', {
    staticClass: "tip"
  }, [_c('i', {
    on: {
      "click": _vm.showTip
    }
  }, [_vm._v("?")]), _c('div', {
    staticClass: "soon"
  }, [_vm._v("SOON")])])]), _c('div', {
    staticClass: "with_btn btn-d"
  }, [_vm._v(" " + _vm._s(_vm.$lang('withdraw')) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "total"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "total__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-pph-1.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "total__title"
  }, [_vm._v(_vm._s(_vm.$lang('Total profit per hour')))]), _c('div', {
    staticClass: "total__num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.totalHourEearnings, 2)))])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "total__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-pph-2.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "total__title"
  }, [_vm._v(_vm._s(_vm.$lang('Profit per hour')))]), _c('div', {
    staticClass: "total__num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.hourEearnings, 2)))])])])]), _vm.pphList.length > 0 ? _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs__title"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Ranking List")))]), _c('div', {
    staticClass: "sub-t"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-pph-2.png`)
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Profit per hour")))])]), _c('div', {
    staticClass: "fs-list"
  }, _vm._l(_vm.pphList, function (item, index) {
    return _c('div', {
      staticClass: "fs-item"
    }, [_c('div', {
      staticClass: "item-icon"
    }, [index === 0 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-1.png`),
        "alt": ""
      }
    }) : index === 1 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-2.png`),
        "alt": ""
      }
    }) : index === 2 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-3.png`),
        "alt": ""
      }
    }) : _c('span', [_vm._v(_vm._s(index + 1))])]), _c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))])]), _c('div', {
      staticClass: "item-num"
    }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(item.earnings, 2)) + " ")])], 1);
  }), 0)]) : _vm._e()])]), _c('pop-pph-tip', {
    attrs: {
      "show": _vm.popPPHTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popPPHTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };