import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData", "robotBox"])
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    type: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$parent.popRobotShow = false;
    }
  }
};