var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "bitgetwallet page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/taskimg/photo_2024-08-01_15-18-51.jpg",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "time-box"
  }, [_c('div', {
    staticClass: "time-box-left"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/okxwallet-live.png`),
      "alt": ""
    }
  }), _c('div', [_vm._v("Live Now")])]), _c('div', {
    staticClass: "time-box-right"
  }, [_vm._v(" 31/07/2024 - 13/08/2024 ")])]), _vm._l(_vm.subTaskList, function (item, index) {
    return _c('div', {
      staticClass: "content-box"
    }, [_c('div', {
      staticClass: "content-box-num"
    }, [_vm._v(_vm._s(index + 1))]), _c('div', {
      staticClass: "content-box-title"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "content-box-desc"
    }, [item.type == 'wallet' ? [_vm._v("Connect Bitget Wallet daily to get rewards")] : _vm._e(), item.type == 'channel' ? [_vm._v("Join the Bitget Wallet channel")] : _vm._e(), item.type == 'twitter' ? [_vm._v("Follow @" + _vm._s(item.target))] : _vm._e(), item.type == 'link' && item.target == 'Register' ? [_vm._v("Win 200 BGB on 1st trade with Bitget.")] : _vm._e()], 2), _c('div', {
      staticClass: "content-box-reward"
    }, [_vm._v(" Reward "), _c('div', {
      staticClass: "content-box-reward-text"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.reward)) + " ")])]), _c('div', {
      staticClass: "content-box-btn",
      on: {
        "click": function ($event) {
          return _vm.onSkip(item);
        }
      }
    }, [_c('div', {
      staticClass: "content-box-btn-text"
    }, [item.type == 'wallet' ? [_vm._v("Connect")] : _vm._e(), item.type == 'channel' ? [_vm._v("Join")] : _vm._e(), item.type == 'twitter' ? [_vm._v("Follow")] : _vm._e(), item.type == 'link' && item.target == 'Register' ? [_vm._v("Join Bitget")] : _vm._e(), item.type == 'link' && item.target == 'Visit' ? [_vm._v("Visit")] : _vm._e(), item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    }) : _vm._e()], 2), _c('div', {
      staticClass: "invite-arrow"
    }, [item.completed == 1 ? _c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    }) : _vm._e()], 1)])]);
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };