var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "90%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.$parent.rewardData ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": _vm.$parent.rewardData.image,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" " + _vm._s(_vm.$parent.rewardData.title) + " ")]), _c('div', {
    staticClass: "energy__reward__title"
  }, [_vm._v(" Reward ")]), _vm._l(_vm.$parent.rewardData.rewards, function (item, index) {
    return _c('div', {
      staticClass: "energy__num"
    }, [_c('img', {
      attrs: {
        "src": item.image,
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(item.num) + " ")]);
  }), _c('div', {
    staticClass: "btn-confirm",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" GET ")])], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };