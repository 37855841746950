var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "countto"
  }, [_c('div', {
    staticClass: "dataNums in-row"
  }, _vm._l(_vm.valLen, function (_, index) {
    return _c('span', {
      key: index,
      staticClass: "dataOne"
    }, [_c('div', {
      staticClass: "box"
    }, [_c('div', {
      ref: "num_items",
      refInFor: true,
      staticClass: "tt",
      style: _vm.ttClass
    }, _vm._l(10, function (_, idx) {
      return _c('span', [_vm._v(_vm._s(idx))]);
    }), 0)]), (_vm.valLen - 1 - index) % 3 === 0 && _vm.valLen - 1 - index != 0 ? _c('div', {
      staticClass: "lin"
    }, [_vm._v(",")]) : _vm._e()]);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };