import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {};
  },
  methods: {
    async onSubmit() {
      this.$parent.popEngrayTipShow = false;
    }
  }
};