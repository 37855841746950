var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "btn-back",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };