import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    squadList: [],
  },
  mutations: {
    setSquadList: (state, payload) => {
      state.squadList = payload;
    },
  },
  actions: {
    getSquadList: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const rs = await Vue.prototype.$http.post(`/ranking/group`, { level: -1 });
        console.log("rs", rs);
        vailcode(rs, () => {
          console.log("getSquadList", rs.data);
          commit("setSquadList", rs.data);
        });
      });
    },
    joinSquad: ({ state, commit }, groupId) => {
      return new Promise(async (resolve, reject) => {
        try {
          const rs = await Vue.prototype.$http.post("/group/join", { groupId });
          vailcode(
            rs,
            async () => {
              console.log("joinSquad", rs.data.user_group_id);
              commit("user/setUserData", rs.data, { root: true });
              resolve(rs.data.user_group_id);
            },
            () => {
              reject();
            }
          );
        } catch (err) {
          reject();
        }
      });
    },
    squadDetail: ({ state, commit }, groupId) => {
      return new Promise(async (resolve, reject) => {
        const rs = await Vue.prototype.$http.post("/group/info", { groupId });
        vailcode(rs, () => {
          console.log("squadDetail", rs.data);
          // commit("setSquadDetail", rs.data);
          resolve(rs.data);
        });
      });
    },
    checkSquad: ({ state, commit }, group_name) => {
      return new Promise(async (resolve, reject) => {
        const rs = await Vue.prototype.$http.post("/group/info", { groupName: group_name });
        vailcode(rs, () => {
          console.log("squadDetail", rs.data);
          // commit("setSquadDetail", rs.data);
          resolve(rs.data);
        });
      });
    },
    leaveSquad: ({ state, commit, dispatch }, groupId) => {
      return new Promise(async (resolve, reject) => {
        try {
          const rs = await Vue.prototype.$http.post("/group/leave", { groupId });
          vailcode(
            rs,
            async () => {
              console.log("squadDetail", rs.data);
              commit("user/setUserData", rs.data, { root: true });
              // await dispatch("user/getUserData", Vue.prototype.WebApp.initData, { root: true });
              resolve();
            },
            () => {
              reject();
            }
          );
        } catch (err) {
          reject();
        }
      });
    },
    squadRank: ({ state, commit }, groupId) => {
      return new Promise(async (resolve, reject) => {
        const rs = await Vue.prototype.$http.post("/ranking/personal", { level: -1, groupId });
        vailcode(rs, () => {
          console.log("squadDetail", rs.data);
          resolve(rs.data);
          // commit("setSquadList", rs.data);
        });
      });
    },
  },
};
