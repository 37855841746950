var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "coinHero__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/pop-coin-hero-icon-0.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "coinHero__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoin Hero")))]), _c('div', {
    staticClass: "coinHero__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$lang("sometimes, We have to be our own hero!"))), _c('br'), _vm._v(" The "), _c('span', [_vm._v("NO.136833")]), _vm._v(" hero ")]), _c('div', {
    staticClass: "btn-learn"
  }, [_vm._v(_vm._s(_vm.$lang("Learn More")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };