var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lottery page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "lottery-main"
  }, [_c('div', {
    staticClass: "lottery-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Lottery List')) + " ")]), _vm.lotterylist.length > 0 ? _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.lotterylist, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.onSkip(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "item-icon"
    }, [_vm._v(" " + _vm._s(item.idx) + " ")]), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_c('div', {
      staticClass: "date"
    }, [_vm._v(_vm._s(item.lottery_date_format))]), _c('div', {
      staticClass: "winners"
    }, [_vm._v("Winners " + _vm._s(item.winners))])]), _c('div', {
      staticClass: "item__num"
    }, [_c('div', {
      staticClass: "pool"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/tron.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.pool)) + " ")]), _c('div', {
      staticClass: "tickers"
    }, [_vm._v(" " + _vm._s(_vm.$lang('Participants')) + " " + _vm._s(item.tickets) + " ")])])])]);
  }), 0) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };