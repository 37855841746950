var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: ['vue-lucky-wheel-item', {
      'default-background': _vm.defaultBackground
    }],
    style: _vm.style
  }, [_c('div', {
    staticClass: "vue-lucky-wheel-item-background",
    style: _vm.styleBackground
  }), _c('div', {
    staticClass: "vue-lucky-wheel-item-content",
    style: _vm.styleContent
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };