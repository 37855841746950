import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const squadVueX = createNamespacedHelpers("squad");
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import { getLevelName } from "@utils/index";
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "popSquad",
  props: {
    // squadId: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      detail: null,
      level: 0,
      isFlag: true
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    getLevelName() {
      return getLevelName;
    }
  },
  watch: {
    "$parent.selectSquadId": {
      async handler(id) {
        this.setLevel();
        this.detail = await this.squadDetail(id);
        console.log("this.detail", this.detail);
        console.log("squadId", this.$parent.selectSquadId);
      }
      // deep: true,
      // immediate: true
    }
  },

  methods: {
    ...squadVueX.mapActions(["squadDetail", "joinSquad"]),
    onSkip() {
      this.WebApp.openTelegramLink(`https://t.me/${this.detail.group_id}`);
    },
    setLevel() {
      if (this.level >= loadConfig.level.length - 1) {
        this.level = loadConfig.level.length - 1;
        this.isMaxLevel = true;
      }
      if (this.level <= 0) {
        this.isMinLevel = true;
      }
    },
    async onJoin() {
      this.$parent.popJoinShow = false;
      this.$parent.popSquadShow = false;
      if (this.userData.user_group_id !== this.$parent.selectSquadId) {
        if (this.isFlag) {
          this.isFlag = false;
          try {
            const group_id = await this.joinSquad(this.$parent.selectSquadId);
            console.log("group_id", group_id);
            let timer = setTimeout(() => {
              clearTimeout(timer);
              timer = null;
              this.isFlag = true;
              this.$toasted.success(this.$lang("Join successfully"));
              this.$router.push(`/squad?id=${group_id}`);
            }, 1000);
          } catch (err) {
            this.isFlag = true;
          }
        }
      } else {
        let timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          this.$router.push(`/squad?id=${this.userData.user_group_id}`);
        }, 1000);
      }
    }
  }
};