import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    firendList: [],
    personalList: [],
    groupList: [],
    topleadersList: [],
    pphList:[]
  },
  mutations: {
    setFirendList: (state, payload) => {
      state.firendList = payload;
    },
    setPersonalList: (state, payload) => {
      state.personalList = payload;
    },
    setPPHList: (state, payload) => {
      state.pphList = payload;
    },
  },
  actions: {
    getFirendList: async ({ state, commit }) => {
      const rs = await Vue.prototype.$http.post("/ranking/friend",{});
      vailcode(rs, () => {
        commit("setFirendList", rs.data);
      });
    },
    getPersonalList: async ({ state, commit, dispatch }, level) => {
      const rs = await Vue.prototype.$http.post("/ranking/personal", { level });
      vailcode(rs, () => {
        commit("setPersonalList", rs.data);
      });
    },
    groupList: async ({ state, commit, dispatch }, level) => {
      const rs = await Vue.prototype.$http.post("/ranking/group", { level });
      vailcode(rs, () => {
        commit("setPersonalList", rs.data);
      });
    },
    getPPHList: async ({ state, commit, dispatch }, level) => {
      const rs = await Vue.prototype.$http.post("/ranking/pph", { level });
      vailcode(rs, () => {
        commit("setPPHList", rs.data);
      });
    },
  },
  getters: {},
};
