import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popBindWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {};
  },
  watch: {
    "$parent.popBindWalletShow": {
      handler(bool) {
        if (bool) {
          console.log("this.tonWallett", this.tonWallet);
        }
      },
      deep: true,
      immediate: true
    },
    tonConnect: {
      async handler(newVal, oldVal) {
        if (newVal) {
          let rs = await this.$http.post("user/bindwallet", {
            wallet: this.tonWallet.uiAddress
          });
          if (rs.code == 0) {
            let newUserData = {
              ...this.userData,
              ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
            };
            this.setUserData(newUserData);
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    onDisconnect() {
      this.tonWallet.disconnect();
    },
    onTonConnect() {
      this.$parent.popBindWalletShow = false;
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
      });
    }
  }
};