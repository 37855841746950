import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    assetsChainsList: [],
    assetsList: [],
    chainsInfo: {},
    assetsInfo: {},
    assetsLog:[],
    logHasMore : false,
    logLoading : false,
    logCondition:{
      chain:"",
      symbol:"",
      page:1,
      limit:20
    }
  },
  mutations: {
    setAssetsChainsList: (state, payload) => {
      state.assetsChainsList = payload;
    },
    setAssetsList: (state, payload) => {
      state.assetsList = payload;
    },
    setChainsInfo: (state, payload) => {
        state.chainsInfo = payload;
    },
    setAssetsInfo: (state, payload) => {
      state.assetsInfo = payload;
    },
    setAssetsLog: (state, payload) => {
        state.assetsLog = payload;
    },
    setLogCondition:(state, payload) => {
      state.logCondition = payload
  },
    setLogLoading:(state, payload) => {
        state.logLoading = payload
    },
    setLogHasMore:(state, payload) => {
        state.logHasMore = payload
    },
    appendAssetsLog:(state, payload) =>{
        state.assetsLog = state.assetsLog.concat(payload)
    },
    updateLogItem:(state, payload)=>{
        state.assetsLog.forEach((item,index) => {
          if(item.id == payload.id){
            Vue.prototype.$set(state.assetsLog,index,payload);
          }
        }); 
    }
  },
  actions: {
    getAssetsChains:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/user/assets/chains",{});
        vailcode(rs, () => {
          commit("setAssetsChainsList", rs.data);
        });
    },
    getAssetsList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/user/assets/list",{});
        vailcode(rs, () => {
          commit("setAssetsList", rs.data);
        });
    },
    getAssetsInfo:async ({ state, commit },[symbol,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/user/assets/info",{symbol});
      vailcode(rs, () => {
          commit("setAssetsInfo", rs.data);
          typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    getAssetsLog:async ({ state, commit },[callback,failcallback]) => {
      if (state.logLoading) return;
      commit('setLogLoading', true);
      const rs = await Vue.prototype.$http.post("/user/assets/withdraw/logs",state.logCondition);
      vailcode(rs, () => {
          if(rs.data.currentPage == 1){
            commit("setAssetsLog", rs.data.items);
          }
          else{
            commit('appendAssetsLog', rs.data.items);
          }
          if(rs.data.lastPage > rs.data.currentPage ){
            commit('setLogHasMore', true);
          }
          else{
            commit('setLogHasMore', false);
          }
          commit('setLogLoading', false);
          typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    doWithdraw:async({ state, commit },[chain,symbol,amount,address,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/user/assets/withdraw",{chain,symbol,amount,address});
      vailcode(rs, () => {
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    getAssetsLogStatus:async ({ state, commit },[orderId,action,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/user/assets/order/check",{orderId:orderId,action:action});
      vailcode(rs, () => {
          commit("updateLogItem", rs.data);
          typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
  },
  getters: {},
};
