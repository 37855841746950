import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "index",
  computed: {
    ...walletVuex.mapState(["theme"])
  },
  methods: {
    preventDefault() {
      this.$nextTick(() => {
        const center = document.querySelector("._indexPage");
        if (!center) return;
        console.log("center", center);
        center.addEventListener("touchmove", function (event) {
          event.preventDefault();
        }, {
          passive: false
        });
      });
    }
  },
  mounted() {
    this.preventDefault();
  }
};