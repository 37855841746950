import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popUpGold",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {};
  }
};