import "core-js/modules/es.array.push.js";
let sUserAgent = navigator.userAgent.toLowerCase();
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import { getLevelName } from "@utils/index";
import loadConfig from "@Web3WalletConfig/config";
const squadVueX = createNamespacedHelpers("squad");
import vailcode from "@utils/errcode";
// import { reflectionjs } from "@assets/js/reflectionjs";
export default {
  name: "index",
  inject: ["reload"],
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["turbo_temp_times", "robotBox", "userData", "turboNum", "totalCoin", "isICountUp", "loginReward"]),
    coinRef() {
      return this.$refs.coinRef;
    },
    reflectionBGRef() {
      return this.$refs.reflectionBGRef;
    },
    getLevelName() {
      return getLevelName;
    },
    isRobot() {
      return this.robotBox.robot && this.robotBox.coin > 0;
    },
    isIos() {
      return /ipad|iphone/.test(sUserAgent);
    },
    enableConfirm() {
      return this.isBeforeDestroyRequest && !this.isRequestCollect;
    }
  },
  watch: {
    ...squadVueX.mapActions(["squadDetail"]),
    isShowTurboScreen: {
      handler(bool) {
        if (bool) {
          this.blockAnimList = [];
          this.setTurboTempTimes(this.userData.turbo_temp_times);
        } else {
          this.setTurboTempTimes(0);
        }
      },
      // deep: true,
      immediate: true
    },
    userData: {
      async handler(newVal, oldVal) {
        this.progressBar = Math.floor(this.userData.power * 100 / this.userData.power_max);
      },
      immediate: true,
      deep: true
    },
    loginReward: {
      async handler(newVal, oldVal) {
        if (this.loginReward.reward > 0) {
          this.popLoginRewardShow = true;
        }
      },
      immediate: true,
      deep: true
    },
    "userData.turbo_temp": {
      handler(val) {
        if (val > 0) {
          this.showTurboIcon();
        }
      },
      deep: true,
      immediate: true
    },
    isRobot: {
      handler(bool) {
        console.log("boooooo", bool);
        this.popRobotShow = bool ? true : false;
      },
      immediate: true,
      deep: true
    },
    popRobotShow(bool) {
      if (!bool) {
        console.log("popRobotShow", bool);
        this.requireCoinCollect();
      }
    },
    isRequestCollect(bool) {
      if (bool) {
        this.requireCoinCollect();
      }
    },
    //数据未提交时 关闭小程序时进行拦截
    enableConfirm: {
      async handler(bool) {
        if (bool) {
          await this.WebApp.enableClosingConfirmation();
        } else {
          this.WebApp.disableClosingConfirmation();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      progressBar: 0,
      caclTimeVal: null,
      plusTime: null,
      blockAnimList: [],
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
        startVal: 0
      },
      delay: 0,
      level: 0,
      popRobotShow: false,
      isRequestCollect: false,
      coinClickNumber: 0,
      isBeforeDestroyRequest: false,
      showTurboIconTimer: null,
      isShowTurboIcon: false,
      isShowTurboScreen: false,
      showTurboScreenTimer: false,
      popLoginRewardShow: false,
      taskCount: 0,
      reflectionTime: 1,
      reflectionCanvasHandleFn: null
    };
  },
  mounted() {
    if (this.userData) this.progressBar = Math.floor(this.userData.power * 100 / this.userData.power_max);
    this.setLevel();
    console.log("sdfsdfsd", this.WebApp);
    this.$nextTick(async () => {});
  },
  beforeDestroy() {
    if (this.isBeforeDestroyRequest) {
      this.requireCoinCollect();
    }
    this.blockAnimList = [];
    clearInterval(this.caclTimeVal);
    this.caclTimeVal = null;
    clearTimeout(this.plusTime);
    this.plusTime = null;
    this.showTurboIconTimer = null;
    this.isShowTurboIcon = false;
    clearTimeout(this.showTurboScreenTimer);
    this.showTurboScreenTimer = null;
    this.WebApp.disableClosingConfirmation();
  },
  methods: {
    ...userVuex.mapMutations(["setTurboTempTimes", "setRobotBox", "setUserData", "setTotalCoin", "setIsICountUp"]),
    ...userVuex.mapActions(["collectCoinData"]),
    setLevel() {
      if (this.level >= loadConfig.level.length - 1) {
        this.level = loadConfig.level.length - 1;
        // this.isMaxLevel = true;
      }
    },

    onSkip(page, id) {
      clearTimeout(this.plusTime);
      if (page == "squad") {
        this.$router.push(`/${page}?id=${id}`);
        return;
      }
      this.$router.push(`/${page}`);
    },
    //金币动画
    coinAnim() {
      if (!this.coinRef) return;
      this.coinRef.style.transform = "scale(0.95)";
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.coinRef.style.transform = "scale(1)";
      }, 50);
    },
    reflectionBGAnim() {
      if (!this.reflectionBGRef) return;
      this.reflectionBGRef.style.transform = "scale(1.08, 1.08)";
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.reflectionBGRef.style.transform = "scale(1, 1)";
      }, 100);
    },
    //漂浮动画
    floatAnim(e) {
      const event = e;
      let x = null;
      let y = null;
      if (!this.isMobile) {
        x = event.x;
        y = event.y;
      } else {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      }
      const coinRef = this.$refs.coinRef;
      if (!coinRef) return;
      if (this.blockAnimList.length >= 20) {
        this.blockAnimList = [];
      }
      let timer = null;
      console.log("sadsadasd", this.userData.power, this.userData.multi_tap);
      if (this.turbo_temp_times <= 0 && this.userData.power - this.userData.multi_tap < 0) {
        this.blockAnimList = [];
        return;
      }
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.blockAnimList.push({
          bool: true,
          x: x - coinRef.offsetWidth / 2 - 40 + "px",
          y: y - coinRef.offsetHeight / 2 - (!this.isMobile ? 150 : 190) + "px"
        });
      }, 0);
    },
    //收集动画
    collectAnim(e) {
      this.coinAnim();
      this.floatAnim(e);
    },
    //加速操作
    // turboFn(e) {
    //   this.turboLoc(e);
    // },
    //加速按钮随机出现
    turboLoc() {
      this.$nextTick(() => {
        const coinRef = this.$refs.coinRef;
        const turboRef = this.$refs.turboRef;
        if (!turboRef && !coinRef) return;
        const random = Math.random();
        turboRef.style.left = coinRef.offsetWidth * random + "px";
        turboRef.style.top = 70 + random * coinRef.offsetHeight + "px";
      });
    },
    async requireCoinCollect() {
      let rs = await this.$http.post("/coin/collect", {
        coin: this.totalCoin,
        power: this.userData.power,
        turbo: this.turbo_temp_times > 0 ? 1 : 0
      });
      vailcode(rs, () => {
        this.setTotalCoin(0);
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.setRobotBox({
          robot: rs.data.robot,
          coin: rs.data.coin
        });
      }, () => {});
    },
    calcCollect() {
      this.isBeforeDestroyRequest = true;
      if (this.turbo_temp_times <= 0) {
        if (this.userData.power - this.userData.multi_tap < 0) {
          // this.userData.power = 0
        } else {
          this.userData.coin += this.userData.multi_tap;
          this.userData.power -= this.userData.multi_tap;
          this.setTotalCoin(this.totalCoin + this.userData.multi_tap);
        }
      } else {
        this.userData.coin += this.userData.multi_tap * this.turbo_temp_times;
        this.setTotalCoin(this.totalCoin + this.userData.multi_tap * this.turbo_temp_times);
      }
      clearInterval(this.caclTimeVal);
      this.isRequestCollect = false;
      this.WebApp.enableClosingConfirmation();
      this.coinClickNumber = 0;
      this.caclTimeVal = setInterval(() => {
        this.coinClickNumber++;
        this.isRequestCollect = this.coinClickNumber >= 2 ? true : false;
      }, 1000);
    },
    switchreflectionBg() {
      this.reflectionTime++;
      if (this.reflectionTime > 4) {
        this.reflectionTime = 1;
      }
    },
    setAudio() {},
    handleClick(e) {
      this.calcCollect();
      this.collectAnim(e);
    },
    showTurboIcon() {
      this.isShowTurboIcon = true;
      this.turboLoc();
      this.showTurboIconTimer = setTimeout(() => {
        clearTimeout(this.showTurboIconTimer);
        this.showTurboIconTimer = null;
        this.isShowTurboIcon = false;
      }, 10000);
    },
    onShowTurboScreen() {
      this.isShowTurboScreen = true;
      this.isShowTurboIcon = false;
      this.showTurboScreenTimer = setTimeout(() => {
        clearTimeout(this.showTurboScreenTimer);
        this.showTurboScreenTimer = null;
        let _timer = setTimeout(async () => {
          this.isShowTurboScreen = false;
          clearTimeout(_timer);
          _timer = null;
          await this.requireCoinCollect();
        }, 100);
      }, 10000);
    }
  }
};