import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {};
  },
  props: {
    award: {
      type: Number,
      default: 0
    }
  },
  methods: {
    async onSubmit() {
      this.$parent.popAwardShow = false;
    }
  }
};