var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "100%",
      "isLoc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "item",
    on: {
      "click": _vm.onTonConnect
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/TONwallet.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v("Ton Connect")])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };