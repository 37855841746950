export default {
  name: 'vue-luckywheel-item',
  data() {
    return {
      itemNumber: 0,
      defaultBackground: this.$parent.defaultBackground,
      size: this.$parent.size
    };
  },
  computed: {
    index() {
      let index = 0;
      this.$parent.$children.some((children, i) => {
        if (children === this) {
          index = i;
          return true;
        } else {
          return false;
        }
      });
      return index;
    },
    centerAngle() {
      return 360 / this.itemNumber;
    },
    style() {
      return {
        transform: `rotate(${this.centerAngle * this.index}deg)`,
        width: `${this.size / 2}px`,
        height: `${this.size / 2}px`
      };
    },
    styleBackground() {
      return {
        transform: `skewY(-${90 - this.centerAngle}deg)`,
        width: `${this.size / 2}px`,
        height: `${this.size / 2}px`
      };
    },
    styleContent() {
      return {
        transform: `translateX(-50%) rotate(${this.centerAngle / 2}deg)`,
        width: `${2 * (this.size / 2) * Math.sin(this.centerAngle / 2 * (Math.PI / 180))}px`,
        height: `${this.size / 2}px`
      };
    }
  },
  mounted() {
    this.itemNumber = this.$parent.$children.length;
  }
};