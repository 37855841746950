import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const squadVuex = createNamespacedHelpers("squad");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config.json";
export default {
  name: "join",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...squadVuex.mapState(["squadList"]),
    squadId() {
      console.log("this.$route.query", this.$route.query);
      return this.$route.query.id;
    }
  },
  data() {
    return {
      popJoinShow: false,
      popSquadShow: false,
      selectSquadId: 0
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popSquadShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {
        if (this.userData.id) {
          await this.getSquadList();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...squadVuex.mapActions(["getSquadList"]),
    onJoin() {
      this.WebApp.openTelegramLink(`https://t.me/${this.setting.botname}?start=joinGroup`);
      this.WebApp.close();
    },
    onRecommend(item) {
      this.popSquadShow = true;
      this.selectSquadId = item.id;
      console.log("this.selectSquadId", this.selectSquadId);
    }
  },
  mounted() {}
};