var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "80%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.extraData ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-3.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.extraData.count > 1 ? _vm.$lang(`Invite ${_vm.extraData.count} friend`) : _vm.$lang(`Invite ${_vm.extraData.count} friends`)))]), _c('div', {
    staticClass: "info"
  }, [_vm._v("Unlock "), _c('i', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.extraData.reward)))]), _vm._v(" " + _vm._s(_vm.setting.coin) + " when you reach this level.")]), _c('div', {
    staticClass: "btn-claim",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onClaim
    }
  }, [_vm._v(_vm._s(_vm.$lang("Claim")))])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };