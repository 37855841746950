import VanillaTilt from './vanilla-tilt.js';
export default {
  name: "myTile",
  props: {
    max: {
      type: [Number, String],
      default: 10
    },
    glare: {
      type: Boolean,
      default: false
    },
    glarecolor: {
      type: String,
      default: "yellow"
    }
  },
  mounted() {
    this.initTile();
  },
  methods: {
    initTile() {
      this.$nextTick(() => {
        if (this.$refs.jsTile) {
          const element = this.$refs.jsTile;
          VanillaTilt.init(element, {
            max: this.max || 10,
            glare: this.glare,
            'max-glare': this.glare && 1,
            'glare-prerender': this.glare && true,
            'speed': 200
          });
        }
      });
    }
  }
};