var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "90vh",
      "width": "100%",
      "isLoc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Select Token")))]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "scroll"
  }, _vm._l(_vm.assetsList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.onSelect(item);
        }
      }
    }, [_c('div', {
      staticClass: "item__icon"
    }, [_c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item-info__title"
    }, [_vm._v(_vm._s(item.symbol))]), _c('div', {
      staticClass: "item-info__label"
    }, [_vm._v(_vm._s(_vm._f("nFormatter")(item.balance, 2)))])]), _vm.assetsInfo.symbol === item.symbol ? _c('div', {
      staticClass: "tag"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })], 1) : _vm._e()]);
  }), 0)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };