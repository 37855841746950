import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const dailyViex = createNamespacedHelpers("daily");
export default {
  name: "daily-invite",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...dailyViex.mapState(["inviteSteps", "inviteTomorrowDistance"])
  },
  data() {
    return {
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: '',
      popShareShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.lastTimer) {
      clearInterval(this.lastTimer);
    }
    this.popShareShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    await this.getSteps([2]);
    this.lastTime = this.inviteTomorrowDistance;
    this.countDown();
  },
  methods: {
    ...dailyViex.mapActions(["getSteps", "updatedClaim"]),
    onBtnSend() {
      this.popShareShow = true;
    },
    sign_in(item, index) {
      if (item.completed && item.claimed) {
        this.$loading.show();
        this.updatedClaim([2, index, () => {
          this.$loading.hide();
        }, () => {
          this.$loading.hide();
        }]);
      }
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          clearInterval(this.lastTimer);
        }
      }, 1000);
    }
  }
};