const requireModules = require.context("./direct", false, /.js$/);
const directive = requireModules.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/v-(.*)\.\w+$/, "$1");
  modules[moduleName] = requireModules(modulePath).default;
  return modules;
}, {});

export default {
  install(Vue) {
    for (let func in directive) {
      Vue.directive(func, directive[func]);
    }
  },
};
