var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/award_tip.jpg`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Guess the hint within the countdown to win the prize")) + " ")]), _c('div', {
    staticClass: "btn-level",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("I hope I'm lucky")) + " ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };