import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import cookie from "js-cookie";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {};
  },
  props: {},
  watch: {},
  methods: {
    async onSubmit() {
      this.$parent.popGetRewardTipShow = false;
    }
  }
};