/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./taskDetail.vue?vue&type=template&id=165840eb&scoped=true"
import script from "./taskDetail.vue?vue&type=script&lang=js"
export * from "./taskDetail.vue?vue&type=script&lang=js"
import style1 from "./taskDetail.vue?vue&type=style&index=1&id=165840eb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165840eb",
  null
  
)

export default component.exports