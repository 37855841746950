import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    luckyList: [],
    luckyItems:[],
    luckyLogs:[],
    luckyLogHasMore : false,
    luckyLogLoading : false,
    luckyLogCondition:{
      lotteryId:"",
      page:1,
      limit:20
    }
  },
  mutations: {
    setLuckyList: (state, payload) => {
      state.luckyList = payload;
    },
    setLuckyItems: (state, payload) => {
      state.luckyItems = payload;
    },
    setLuckyLogs: (state, payload) => {
      state.luckyLogs = payload;
    },
    setLuckyLogCondition:(state, payload) => {
      state.luckyLogCondition = payload
    },
    setLuckyLogLoading:(state, payload) => {
        state.luckyLogLoading = payload
    },
    setLuckyLogHasMore:(state, payload) => {
        state.luckyLogHasMore = payload
    },
    appendLuckyLog:(state, payload) =>{
        state.luckyLogs = state.luckyLogs.concat(payload)
    },
  },
  actions: {
    getLuckyList:async ({ state, commit },[provider,type]) => {
        const rs = await Vue.prototype.$http.post("/lucky/lottery/list",{provider,type});
        vailcode(rs, () => {
          commit("setLuckyList", rs.data);
        });
    },
    getLuckyItems:async ({ state, commit },lotteryId) => {
      const rs = await Vue.prototype.$http.post("/lucky/lottery/items",{lotteryId});
      vailcode(rs, () => {
        commit("setLuckyItems", rs.data);
      });
    },
    getLuckyLogs:async ({ state, commit },[callback,failcallback]) => {
      if (state.luckyLogLoading) return;
      commit('setLuckyLogLoading', true);
      const rs = await Vue.prototype.$http.post("/lucky/lottery/logs",state.luckyLogCondition);
      vailcode(rs, () => {
          if(rs.data.currentPage == 1){
            commit("setLuckyLogs", rs.data.items);
          }
          else{
            commit('appendLuckyLog', rs.data.items);
          }
          if(rs.data.lastPage > rs.data.currentPage ){
            commit('setLuckyLogHasMore', true);
          }
          else{
            commit('setLuckyLogHasMore', false);
          }
          commit('setLuckyLogLoading', false);
          typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    luckyDraw:async ({ state, commit },[lotteryId,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/lucky/lottery/draw",{lotteryId});
      vailcode(
        rs,
        () => {
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
  },
  getters: {},
};
