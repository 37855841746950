import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
import vailcode from "@utils/errcode";
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "popAirdropWithdraw",
  props: {},
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      uid: '',
      address: "",
      isDisabled: false,
      isFlag: true
    };
  },
  watch: {
    "$parent.popBitgetUidSubmitShow": {
      async handler(bool) {
        if (bool && this.$parent.registerTask.completed == 1) {
          let rs = await this.$http.post("/task/completion/info", {
            taskId: this.$parent.registerTask.id,
            kind: "sub"
          });
          if (rs.code == 0) {
            this.uid = rs.data.data.value;
            this.address = rs.data.data.address;
            this.isDisabled = true;
          }
        } else if (!bool) {
          this.isDisabled = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {},
  methods: {
    ...taskVuex.mapMutations(["setTaskComplete"]),
    ...taskVuex.mapActions(["getTaskList", "getSubTaskList", "doSubTask"]),
    ...userVuex.mapMutations(['setUserData']),
    cancelFn() {
      this.$emit("cancel");
    },
    openView() {
      this.WebApp.openLink("https://docs.google.com/document/d/1JMrbr4qVPv5Nhu-ubXbJ7nQg0H0Ted77JZ20LSiCT4I/edit?usp=sharing");
    },
    openBitget() {
      this.WebApp.openLink("https://bitget.onelink.me/XqvW?af_xp=custom&pid=GOLDVERSE");
    },
    async confirmFn() {
      if (!this.isFlag || this.$parent.registerTask.completed == 1) return;
      if (!this.uid) {
        this.$toasted.error('Please enter Bitget Exchange UID');
        return false;
      }
      if (!this.address) {
        this.$toasted.error('Please enter $GDV Deposit address');
        return false;
      }
      this.isFlag = false;
      let rs = await this.$http.post("/task/complete", {
        taskId: this.$parent.registerTask.id,
        kind: "sub",
        value: this.uid,
        address: this.address
      });
      this.isFlag = true;
      vailcode(rs, () => {
        let newUserData = {
          ...this.userData,
          ...(rs.data && rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.isDisabled = true;
        this.$parent.registerTask.completed = 1;
      });
    }
  }
};