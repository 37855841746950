import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
import { toNano } from "@ton/core";
export default {
  name: "bitgetWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting', 'tonConnect']),
    ...taskVuex.mapState(["taskList"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      timeVal: null,
      subTaskList: [],
      registerTask: {},
      receivetask: {},
      quota: {},
      popBitgetUidSubmitShow: false,
      isFlag: true,
      disabled: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popBitgetUidSubmitShow = false;
    this.isFlag = true;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    // if(this.tonConnect)
    //   this.tonWallet.ton.disconnect();
    this.taskId = this.$route.query.id;
    await this.getSubTaskList([this.taskId, items => {
      items.map(item => {
        if (item.type == 'airdrop') {
          this.receivetask = item;
        } else {
          this.registerTask = item;
        }
      });
    }]);
    let rs = await this.$http.post("/task/airdrop/quota", {
      taskName: "bitget"
    });
    if (rs.code == 0) {
      this.quota = rs.data;
    }
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapMutations(["setTaskComplete"]),
    ...taskVuex.mapActions(["getTaskList", "getSubTaskList", "doSubTask"]),
    ...userVuex.mapMutations(['setUserData']),
    onSubmit() {
      if (!this.quota || !this.quota.amount) {
        this.$toasted.error("You are not eligible to participate");
        return;
      }
      this.popBitgetUidSubmitShow = true;
    },
    async onConfirm() {
      if (this.disabled) return;
      if (!this.tonConnect) this.onTonConnect();else {
        if (!this.quota || !this.quota.amount) {
          this.$toasted.error("You are not eligible to participate");
          return;
        }
        if (!this.isFlag) return;
        this.isFlag = false;
        let rs = await this.$http.post("/task/complete", {
          taskId: this.receivetask.id,
          kind: "sub",
          value: "",
          address: this.tonWallet.uiAddress
        });
        vailcode(rs, () => {
          let newUserData = {
            ...this.userData,
            ...(rs.data && rs.data.userInfo ? rs.data.userInfo : rs.data)
          };
          this.setUserData(newUserData);
          this.receivetask.completed = 1;
          if (rs.data.chainInfo) {
            let msg = [{
              address: rs.data.chainInfo.contract,
              amount: toNano(rs.data.chainInfo.amount).toString(),
              payload: rs.data.chainInfo.raw
            }];
            this.tonWallet.sendTransaction(msg);
          }
          this.isFlag = true;
          if (rs.message) {
            this.$toasted.success(rs.message);
          }
        }, () => {
          this.isFlag = true;
        });
      }
    },
    async onTonConnect() {
      if (this.tonConnect) {
        await this.tonWallet.ton.disconnect();
      }
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
      });
    }
  }
};