export default {
  data() {
    return {
      name: "breeding-rhombus-spinner",
      isShow: false,
      color: "#3fdd78",
      isShowBg: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.vailInit();
      const body = document.querySelector("body");
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }
    });
  },
  methods: {
    vailInit() {
      if (this.color == null) {
        let timer = null;
        timer = setTimeout(() => {
          this.color = this.theme("--color-primary");
          clearTimeout(timer);
        }, 0);
      }
    }
  }
};