var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lottery page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "lottery-main"
  }, [_c('div', {
    staticClass: "lottery-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Participants')) + " "), _c('div', {
    staticClass: "lottery-num"
  }, [_vm._v(_vm._s(_vm.lotteryDetail.tickets))])]), _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lottery-icon.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "head-info"
  }, [_c('div', {
    staticClass: "date"
  }, [_vm._v(" " + _vm._s(_vm.lotteryDetail.lottery_date_format) + " ")]), _c('div', {
    staticClass: "pool-box"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Pool')) + " "), _c('div', {
    staticClass: "pool"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tron.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.lotteryDetail.pool)) + " ")])])]), _vm.lotteryDetail.tstatus !== 1 ? _c('div', {
    staticClass: "head-right"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/time.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.timeLabel) + " ")]), _vm.lotteryDetail.tstatus == 2 ? [_vm._v(" " + _vm._s(_vm.$lang('Waiting to begin')) + " ")] : [_vm.lotteryDetail.has_join == 0 ? _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.onJoinPop
    }
  }, [!_vm.tonConnect ? [_vm._v(" " + _vm._s(_vm.$lang('Ton Connct')) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang('Participate')) + " ")], _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 2) : _vm._e(), _vm.lotteryDetail.has_join == 1 ? _c('div', {
    staticClass: "btn has"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Participated')) + " ")]) : _vm._e()]], 2) : _vm._e()]), _c('div', {
    staticClass: "list-title"
  }, [_vm._v("Winning Record")]), _vm.lotteryDetail.list && _vm.lotteryDetail.list.length > 0 ? _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.lotteryDetail.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/tron.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(Number(_vm.lotteryDetail.pool) / _vm.lotteryDetail.winners)) + " ")])])], 1);
  }), 0) : _vm._e()]), _c('pop-lottery-join', {
    attrs: {
      "show": _vm.popLotteryJoinShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLotteryJoinShow = $event;
      },
      "cancel": _vm.onCancel,
      "confirm": _vm.onConfirm
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };