import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const rankingVuex = createNamespacedHelpers("ranking");
const mineVuex = createNamespacedHelpers("mine");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "pph",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData", 'extraInvite']),
    ...rankingVuex.mapState(["pphList"]),
    ...mineVuex.mapState(["hourEearnings", 'totalHourEearnings'])
  },
  data() {
    return {
      loadConfig,
      extraData: null,
      popPPHTipShow: false
    };
  },
  mounted() {
    if (!this.totalHourEearnings) {
      this.getTotalHourEarnings();
    }
    if (this.pphList.length == 0) this.getPPHList();
  },
  beforeRouteLeave(to, from, next) {
    this.popPPHTipShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...rankingVuex.mapActions(["getPPHList"]),
    ...mineVuex.mapActions(["getTotalHourEarnings"]),
    showTip() {
      this.popPPHTipShow = true;
    }
  }
};