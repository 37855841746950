import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
const assetsVuex = createNamespacedHelpers("assets");
import loadConfig from "@Web3WalletConfig/config";
import { toNano } from "@ton/core";
export default {
  name: "popAirdropWithdraw",
  props: {
    assetsInfo: {
      type: Object,
      default: {}
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect"]),
    ...userVuex.mapState(["userData"]),
    ...assetsVuex.mapState(["assetsLog", "logHasMore", "logLoading", "logCondition"])
  },
  data() {
    return {
      popSelectTokenShow: false,
      withdrawAmount: 0,
      isFlag: true
    };
  },
  watch: {
    "$parent.popBindWalletShow": {
      handler(bool) {
        if (bool) {
          console.log("this.tonWallett", this.tonWallet);
        }
      },
      deep: true,
      immediate: true
    },
    "$parent.popWithdrawShow": {
      handler(bool) {
        if (bool) {
          //this.getAssetsInfo([this.assetsInfo.symbol])
          this.onLoadData(1);
          this.withdrawAmount = this.assetsInfo.balance;
        }
      },
      deep: true,
      immediate: true
    },
    tonConnect: {
      async handler(newVal, oldVal) {
        if (newVal) {
          let rs = await this.$http.post("user/bindwallet", {
            wallet: this.tonWallet.uiAddress
          });
          if (rs.code == 0) {
            let newUserData = {
              ...this.userData,
              ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
            };
            this.setUserData(newUserData);
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {

    //this.getAssetsInfo([this.assetsInfo.symbol])
  },
  methods: {
    ...assetsVuex.mapActions(["doWithdraw", "getAssetsInfo", "getAssetsLog", "getAssetsLogStatus"]),
    ...assetsVuex.mapMutations(["setLogCondition"]),
    ...userVuex.mapMutations(['setUserData']),
    cancelFn() {
      this.$emit("cancel");
    },
    async confirmFn() {
      if (!this.isFlag) return;
      if (this.assetsInfo.balance < 1 || this.withdrawAmount < 1) {
        return;
      }
      if (this.assetsInfo.balance < this.withdrawAmount) {
        this.$toasted.error("Insufficient balance");
        return;
      }
      this.isFlag = false;
      this.$emit("confirm");
      if (this.tonConnect) {
        this.tonWallet.ton.disconnect();
      }
      this.$parent.popBindWalletShow = false;
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
        this.tonWallet.ton.onStatusChange(async walletAndwalletInfo => {
          if (walletAndwalletInfo) {
            let res = await this.doWithdraw([this.assetsInfo.symbol, this.withdrawAmount, this.tonWallet.tranAddress(walletAndwalletInfo.account.address), async res => {
              let msg = [{
                address: res.contract,
                amount: toNano(res.amount).toString(),
                payload: res.raw
              }];
              this.tonWallet.sendTransaction(msg);
              await this.getAssetsInfo([this.assetsInfo.symbol]);
              this.withdrawAmount = this.assetsInfo.balance;
              this.onLoadData(1);
            }]);
            this.isFlag = true;
          } else {
            this.isFlag = true;
          }
        });
      });
    },
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        // 接近底部时触发加载  
        if (this.logHasMore && !this.logLoading) {
          let page = this.logCondition.page;
          this.onLoadData(page + 1);
        }
      }
    },
    onLoadData(page) {
      this.setLogCondition({
        "symbol": this.assetsInfo.symbol,
        "limit": 10,
        "page": page
      });
      this.getAssetsLog([() => {}, () => {}]);
    },
    checkOrderStatus(event, orderId) {
      const element = event.target;
      element.style.animation = 'pop-ref 1s linear forwards';
      setTimeout(() => {
        element.style.animation = ''; // 移除动画属性  
      }, 2000);
      this.getAssetsLogStatus([orderId, "withdraw", async () => {
        await this.getAssetsInfo([this.assetsInfo.symbol]);
        this.withdrawAmount = this.assetsInfo.balance;
      }, () => {}]);
    },
    refresh() {
      const element = this.$refs.popRefresh;
      element.style.animation = 'pop-ref 1s linear forwards';
      setTimeout(() => {
        element.style.animation = ''; // 移除动画属性  
      }, 2000);
      this.onLoadData(1);
      this.$refs.myScroll.scrollTo({
        top: 0,
        left: 0
      });
    },
    amountInput(val) {
      this.withdrawAmount = val.target.value.replace(/(\.\d\d)\d*/, '$1');
    },
    openView(item) {
      this.WebApp.openLink("https://tonviewer.com/transaction/" + item.tx_hash);
    }
  }
};