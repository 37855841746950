var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "airdrop-reward page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_vm.airdropInfo.id || 0 ? _c('div', {
    staticClass: "airdrop-main"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('img', {
    attrs: {
      "src": _vm.airdropInfo.token_assets.icon
    }
  }), _c('div', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.airdropInfo.completion.reward_amount, 2)))])]), _c('div', {
    staticClass: "rule"
  }, _vm._l(_vm.airdropInfo.rules, function (item, index) {
    return _c('div', {
      staticClass: "item"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "task-list"
  }, _vm._l(_vm.airdropInfo.conditions, function (item, index) {
    return _c('div', {
      staticClass: "task-item",
      on: {
        "click": function ($event) {
          return _vm.jumpTo(item);
        }
      }
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('img', {
      attrs: {
        "src": item.icon
      }
    })]), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.title))]), item.tip ? _c('div', {
      staticClass: "line"
    }, [_vm._v(_vm._s(item.tip))]) : _vm._e()]), _c('div', {
      staticClass: "op",
      class: item.completed && 'active'
    }, [item.completed ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })]], 2)]);
  }), 0), _c('div', {
    staticClass: "receive-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": _vm.airdropInfo.token_assets.icon
    }
  })]), _c('div', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.airdropInfo.amount, 2)))]), _c('div', {
    staticClass: "op"
  }, [!_vm.airdropInfo.completion.completed ? [_c('div', {
    staticClass: "btn btn-d"
  }, [_vm._v(_vm._s(_vm.$lang("Receive")))])] : _vm.airdropInfo.completion.completed && !_vm.airdropInfo.completion.reward_received ? [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onRecive(_vm.airdropInfo.id);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Receive")))])] : [_c('div', {
    staticClass: "btn btn-d"
  }, [_vm._v(_vm._s(_vm.$lang("Received")))])]], 2)]), _vm.airdropInfo.invitation_amount > 0 ? [_c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Each verified friend')) + " "), _c('img', {
    attrs: {
      "src": _vm.airdropInfo.token_assets.icon
    }
  }), _vm._v(" "), _c('span', [_vm._v("+" + _vm._s(_vm._f("nFormatter")(_vm.airdropInfo.invitation_amount, 2)))])])]), _c('div', {
    staticClass: "invite-btns"
  }, [_c('div', {
    staticClass: "btn btn-i",
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v(_vm._s(_vm.$lang('Invite')))]), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: `${_vm.airdropInfo.invitation_tip}\nhttps://t.me/${_vm.setting.botname}/app?startapp=ref_${_vm.userData.ref_code}`,
      expression: "`${airdropInfo.invitation_tip}\\nhttps://t.me/${setting.botname}/app?startapp=ref_${userData.ref_code}`"
    }],
    staticClass: "btn btn-copy"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  })], 1)]), _vm.airdropInvitation.total > 0 ? _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Verified Friends")) + " "), _c('span', [_vm._v("(" + _vm._s(_vm.airdropInvitation.total) + ")")])]), _c('div', {
    staticClass: "fs-list"
  }, _vm._l(_vm.airdropInvitation.items, function (item, index) {
    return _c('div', {
      staticClass: "fs-item"
    }, [_c('m-head', {
      attrs: {
        "name": {
          firstname: item.from_user_firstname,
          lastname: item.from_user_lastname
        },
        "bgColor": item.from_user_head_color
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.from_user_firstname) + " " + _vm._s(item.from_user_lastname))])]), _c('div', {
      staticClass: "op"
    }, [_c('img', {
      attrs: {
        "src": _vm.airdropInfo.token_assets.icon
      }
    }), _vm._v(" "), _c('span', [_vm._v("+" + _vm._s(_vm._f("nFormatter")(item.amount, 2)))])])], 1);
  }), 0)]) : _vm._e()] : _vm._e()], 2) : _vm._e()]), _c('pop-earn-tip', {
    attrs: {
      "show": _vm.popEarnTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popEarnTipShow = $event;
      }
    }
  }), _c('pop-share', {
    attrs: {
      "show": _vm.popShareShow,
      "shareTxt": _vm.airdropInfo.invitation_tip
    },
    on: {
      "update:show": function ($event) {
        _vm.popShareShow = $event;
      }
    }
  }), _c('pop-reward-result', {
    attrs: {
      "show": _vm.isPopRewardResultShow,
      "select-row": _vm.selectRow,
      "showBtn": false,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopRewardResultShow = $event;
      },
      "accept": _vm.onAccept
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };