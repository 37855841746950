/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./svgIcon.vue?vue&type=template&id=df5a9ee0&scoped=true"
import script from "./svgIcon.vue?vue&type=script&lang=js"
export * from "./svgIcon.vue?vue&type=script&lang=js"
import style0 from "./svgIcon.vue?vue&type=style&index=0&id=df5a9ee0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df5a9ee0",
  null
  
)

export default component.exports