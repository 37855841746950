var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "80%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "squad-img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/join-icon-frens.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "squad-title"
  }, [_vm._v(_vm._s(_vm.$lang("Join squad")))]), _c('div', {
    staticClass: "squad-subtitle"
  }, [_vm._v(_vm._s(_vm.$lang("Just enter the public group or channel link below and click join to automatically join or create a squad")))]), _c('div', {
    staticClass: "squad-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.squadName,
      expression: "squadName"
    }],
    attrs: {
      "type": "text",
      "placeholder": `eg.t.meMtheYescoin ar @${_vm.setting.coin}`
    },
    domProps: {
      "value": _vm.squadName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.squadName = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "btn-join",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onJoin
    }
  }, [_vm._v(_vm._s(_vm.$lang("Join")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };