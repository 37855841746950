import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import cookie from "js-cookie";
export default {
  name: "popAirdripTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData", "token"])
  },
  watch: {
    "$parent.popEarnTipShow": {
      handler(bool) {
        if (bool) {
          this.itemData = this.$parent.itemData;
          this.leftTime = 0;
          this.checkNum = 0;
          this.isCheck = false;
          this.cacheKey = `TaskClick_${this.userData.id}_${this.itemData.id}}`;
          this.isClick = cookie.get(this.cacheKey) ? true : false;
        } else {
          clearTimeout(this.timeVal);
        }
      }
    }
  },
  data() {
    return {
      itemData: null,
      timeVal: "",
      leftTime: 0,
      checkNum: 0,
      isCheck: false,
      cacheKey: "",
      isClick: false
    };
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapActions(["getSubTaskList", "taskClick", "doSubTask"]),
    ...userVuex.mapMutations(['setUserData']),
    onClick() {
      if (this.itemData.type == 'telegram' || this.itemData.type == 'channel') {
        this.WebApp.openTelegramLink(this.itemData.url);
      } else if ((this.itemData.type || '') == 'advPlatForm' || (this.itemData.provider || '') == 'advPlatForm') {
        this.WebApp.openTelegramLink(`${this.setting.advapp}?startapp=${this.token}=${this.itemData.id}-${this.itemData.adv}`);
        this.WebApp.close();
      } else {
        this.WebApp.openLink(this.itemData.url);
      }
      if (this.itemData.completed) {
        this.$parent.popEarnTipShow = false;
      } else {
        cookie.set(this.cacheKey, "1", {
          expires: 1
        });
        this.isClick = true;
        this.isCheck = true;
        if (this.itemData.verifiable) this.leftTime = 5;else this.leftTime = 20;
        this.countDown();
      }
    },
    onCheck() {
      if (this.itemData.completed) {
        this.$parent.popEarnTipShow = false;
      } else {
        this.isCheck = true;
        if (this.itemData.verifiable) this.leftTime = 5;else this.leftTime = 20;
        this.countDown();
      }
    },
    countDown() {
      clearTimeout(this.timeVal);
      this.leftTime--;
      if (this.leftTime > 0) {
        this.timeVal = setTimeout(() => {
          this.countDown();
        }, 1000);
      } else {
        this.onExtralJoin(this.itemData, 0);
      }
    },
    async onExtralJoin(item, adv = 0) {
      console.log("url=====", item.url);
      this.checkNum = 0;
      if (!item.completed) {
        this.checkExtralTask(item, adv);
      } else {
        this.$parent.popEarnTipShow = false;
      }
    },
    async checkExtralTask(item, adv) {
      clearTimeout(this.timeVal);
      if (this.checkNum >= 2) {
        this.isCheck = false;
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv,
        kind: this.itemData.kind || null
      });
      if (rs.code == 0) {
        item.completed = 1;
        if (adv == 1 && item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.isCheck = false;
        this.$parent.popEarnTipShow = false;
        this.$parent.isPopRewardResultShow = true;
        if (adv == 0) this.loadTaskCount();
      } else if (rs.code == 302) {
        this.timeVal = setTimeout(() => {
          this.checkExtralTask(item, adv);
        }, 2000);
      }
    }
  }
};