var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Airdrip & Freemint")) + " ")]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" Prepare for Airdrop and Freemint:"), _c('br'), _vm._v("1. Join Channel"), _c('br'), _vm._v("2. Connect TON wallet"), _c('br'), _vm._v("3. Upgrade mine (more probability)"), _c('br'), _vm._v("4. Invite friends (more probability) ")]), _c('div', {
    staticClass: "btn-level",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("I'm ready")) + " ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };