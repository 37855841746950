var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "squad page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "squad-main"
  }, [_vm.detail != null ? [_c('div', {
    staticClass: "head"
  }, [_c('m-head', {
    attrs: {
      "src": _vm.detail.group_icon,
      "size": 50
    }
  }), _c('div', {
    staticClass: "head__label"
  }, [_vm._v(_vm._s(_vm.detail.group_name))])], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__img level"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/level/${_vm.getLevelName(_vm.level)}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_vm._v(_vm._s(_vm.detail.group_level))])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-2.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.detail.group_coin, 3, true)))])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-3.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.detail.user_count, 3, true)) + " ")])]), _c('div', {
    staticClass: "info-item",
    on: {
      "click": _vm.onSkip
    }
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-4.png`),
      "alt": ""
    }
  })]), _vm._m(0)])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn invite",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v(_vm._s(_vm.$lang("Invite friend")))]), _c('div', {
    staticClass: "btn leave",
    style: {
      background: _vm.themeMainColor(_vm.theme).second
    },
    on: {
      "click": _vm.leaveSquadFn
    }
  }, [_vm._v(_vm._s(_vm.$lang("Leave Squad")))])]), _vm.rankList.length > 0 ? _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.rankList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "item-icon"
    }, [index === 0 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-1.png`),
        "alt": ""
      }
    }) : index === 1 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-2.png`),
        "alt": ""
      }
    }) : index === 2 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-3.png`),
        "alt": ""
      }
    }) : _c('span', [_vm._v(_vm._s(index + 1))])]), _c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.coin, 3, true)) + " ")])])], 1);
  }), 0) : _vm._e()] : _vm._e()], 2), _c('pop-prompt', {
    attrs: {
      "show": _vm.popPromptShow,
      "title": _vm.$lang('Are you sure you want to exit?')
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptShow = $event;
      },
      "cancel": _vm.onCancel,
      "confirm": _vm.onConfirm
    }
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "info__label"
  }, [_c('i', [_vm._v("Community")])]);
}];
export { render, staticRenderFns };