export default {
  name: "myHead",
  props: {
    src: {
      default: null,
      type: String
    },
    bgColor: {
      type: String,
      default: "#fff"
    },
    name: null,
    size: {
      type: [String, Number],
      default: "40"
    }
  }
};