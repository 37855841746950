var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "code page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "code-main"
  }, [_c('div', {
    staticClass: "expire"
  }, [_c('div', {
    staticClass: "expire-img",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/code-icon-0.png`)})`
    }
  }), _c('div', {
    staticClass: "expire-main"
  }, [_c('div', {
    staticClass: "expire-info"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Secret Code")) + " "), _c('p', [_vm._v(_vm._s(_vm.$lang("expire in")) + ": "), _c('i', [_vm._v("22:30:28")])])]), _c('div', {
    staticClass: "expire-input"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$lang('Input your secret code here')
    }
  })]), _c('div', {
    staticClass: "btn-claim",
    on: {
      "click": _vm.onClaim
    }
  }, [_vm._v(_vm._s(_vm.$lang("Claim")))])])]), _c('div', {
    staticClass: "invite"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("How To Use Secret Code")))]), _c('div', {
    staticClass: "invite-main"
  }, [_c('div', {
    staticClass: "invite-item"
  }, [_c('div', {
    staticClass: "invite__img"
  }), _c('div', {
    staticClass: "invite__label"
  }, [_vm._v(_vm._s(_vm.$lang(`Get a secret code from your friend or mining channel`)))])]), _c('div', {
    staticClass: "invite-item"
  }, [_c('div', {
    staticClass: "invite__img"
  }), _c('div', {
    staticClass: "invite__label",
    domProps: {
      "innerHTML": _vm._s(_vm.$lang(`You and code's owner both get <i>100K</i> and random bonus.`))
    }
  })]), _c('div', {
    staticClass: "invite-item"
  }, [_c('div', {
    staticClass: "invite__img"
  }), _c('div', {
    staticClass: "invite__label",
    domProps: {
      "innerHTML": _vm._s(_vm.$lang(`If you are Telegram Premium both get <i>500K</i> and random bonus.`))
    }
  })])])]), _c('div', {
    staticClass: "share"
  }, [_c('div', {
    staticClass: "share-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Follow")))]), _c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1), _c('div', {
    staticClass: "share-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Follow")))]), _c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1)])]), _c('pop-get-award', {
    attrs: {
      "show": _vm.popGetAwaitShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGetAwaitShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };