var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.$parent.tipData.title ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": _vm.$parent.tipData.icon,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" " + _vm._s(_vm.$parent.tipData.title) + " ")]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$parent.tipData.brief) + " ")]), _c('div', {
    staticClass: "btns"
  }, _vm._l(_vm.$parent.tipData.btns, function (item, index) {
    return _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onSubmit(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };