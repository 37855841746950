import "core-js/modules/es.array.push.js";
export default {
  name: 'luckywheel',
  props: {
    defaultBackground: {
      type: Boolean,
      default: true
    },
    rotateTime: {
      type: Number,
      default: 6
    },
    prizeIndex: {
      type: Number
    }
  },
  data() {
    return {
      itemNumber: 0,
      rotateAngle: 0,
      startAngle: 0,
      isDrawing: false
    };
  },
  computed: {
    style() {
      const luckyTableMain = document.getElementById("luckyTableMain");
      if (!luckyTableMain) return;
      return {
        width: `${luckyTableMain.offsetWidth * 0.8}px`,
        height: `${luckyTableMain.offsetHeight * 0.8}px`
      };
    },
    size() {
      const luckyTableMain = document.getElementById("luckyTableMain");
      return luckyTableMain.offsetWidth * 0.8;
    },
    mainStyle() {
      return {
        ...this.style,
        transform: `rotate(${this.rotateAngle}deg)`,
        transition: `transform ${this.rotateTime}s ease-in-out`
      };
    },
    prizeAngleList() {
      let itemNumber = this.itemNumber;
      const onePrizeAngle = 360 / itemNumber / 2;
      const prizeAngleList = [];
      while (itemNumber > 0) {
        prizeAngleList.push(onePrizeAngle + --itemNumber * onePrizeAngle * 2);
      }
      return prizeAngleList;
    }
  },
  mounted() {
    this.itemNumber = this.$children.length;
  },
  methods: {
    getPrize() {
      if (this.isDrawing) {
        return;
      }
      this.isDrawing = true;
      this.$emit('get-prize');
    },
    draw() {
      this.rotateAngle = this.startAngle + 6 * 360 + this.prizeAngleList[this.prizeIndex] - this.startAngle % 360;
      this.startAngle = this.rotateAngle;
      setTimeout(() => {
        this.isDrawing = false;
        this.$emit('game-over');
      }, this.rotateTime * 1000);
    }
  }
};