import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const lotteryVuex = createNamespacedHelpers("lottery");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
import loadConfig from "@Web3WalletConfig/config.json";
export default {
  name: "popJoin",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...lotteryVuex.mapState(["lotteryDetail"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      isFlag: true
    };
  },
  watch: {
    "$parent.selectLotteryId": {
      async handler(id) {
        await this.getLotteryDetail(id);
      }
      // deep: true,
      // immediate: true
    }
  },

  methods: {
    ...lotteryVuex.mapActions(["getLotteryDetail"]),
    ...userVuex.mapMutations(["setUserData"]),
    async onJoin() {
      if (!this.isFlag) return;
      this.isFlag = false;
      let rs = await this.$http.post("/lottery/join", {
        id: this.$parent.selectLotteryId,
        wallet: this.tonWallet.uiAddress
      });
      this.isFlag = true;
      vailcode(rs, () => {
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.getLotteryDetail(this.$parent.selectLotteryId);
      });
    }
  }
};