import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popMethod",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {};
  },
  watch: {
    "$parent.popMethodShow": {
      handler(bool) {
        if (bool) {
          console.log("this.tonWallett", this.tonWallet);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onTonConnect() {
      this.$parent.popMethodShow = false;
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
      });
    }
  }
};