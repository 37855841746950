import Vue from "vue";
import VueRouter from "vue-router";
import autoRouter from "vue-router-auto";
// import { metaList } from "./meta";

Vue.use(VueRouter);

//修改原型对象中的push方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = autoRouter({
  rc: require.context("@/view", true, /\.vue$/),
  rootFile: "view",
});

// function setRouteFn(route, metaArr) {
//   if (route.children) {
//     for (const item of route.children) setRouteFn(item, metaList);
//   }
//   for (const key in metaArr) {
//     if (route.name === key) route["meta"] = metaArr[key];
//   }
// }

// if (JSON.stringify(metaList) !== "") for (const item of routes) setRouteFn(item, metaList);

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition && to.meta.keepAlive) return savedPosition;
  // 异步滚动操作
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        x: 0,
        y: 1,
      });
    }, 0);
  });
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  scrollBehavior,
});

console.log("router====", routes);

router.beforeEach((to, from, next) => {
  next();
});

export default router;
