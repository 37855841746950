import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const airdropVuex = createNamespacedHelpers("airdrop");
export default {
  name: "activity",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...airdropVuex.mapState(["airdropList"])
  },
  methods: {
    ...airdropVuex.mapActions(["getAirdropList"]),
    jumpTo(url) {
      this.$router.push(url);
    }
  },
  async mounted() {
    if (this.airdropList.length == 0) {
      await this.getAirdropList();
    }
  }
};