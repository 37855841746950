var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "jsTile",
    staticClass: "myTile js-tilt",
    class: _vm.glare && 'js-tilt-glare'
  }, [_vm.glare ? _c('div', {
    staticClass: "js-tilt-glare-inner",
    style: {
      '--glarecolor': _vm.glarecolor
    }
  }) : _vm._e(), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };