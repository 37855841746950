var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "daily-invite page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "daily-invite-main"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/daily-invite.png`)
    }
  })]), _c('div', {
    staticClass: "head__title"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Reward")))]), _vm._m(0)]), _c('div', {
    staticClass: "items"
  }, _vm._l(_vm.inviteSteps, function (item, index) {
    return _c('div', {
      staticClass: "item",
      class: item.today && 'item__today',
      on: {
        "click": function ($event) {
          return _vm.sign_in(item, index);
        }
      }
    }, [_c('div', {
      staticClass: "item__box",
      class: item.completed && 'has_sign'
    }, [_c('div', {
      staticClass: "item__header"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "item__main"
    }, [_c('div', {
      staticClass: "item__content__header"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-user"
      }
    }), _vm._v(" +" + _vm._s(item.required) + " ")], 1), _c('div', {
      staticClass: "item__content"
    }, [item.completed ? _c('svg-icon', {
      staticClass: "sign_img",
      attrs: {
        "name": "icon-ok"
      }
    }) : _vm._e(), _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`)
      }
    }), _c('div', {
      staticClass: "item__coin"
    }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(item.coins)) + " ")])], 1)])]), item.completed && !item.claimed ? _c('div', {
      staticClass: "item__title"
    }, [_vm._v(" Claim ")]) : _vm._e(), item.today && !item.completed ? _c('div', [_vm._v(" " + _vm._s(_vm.lastTimeStr) + " ")]) : _vm._e()]);
  }), 0)]), _c('div', {
    staticClass: "btn-send",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onBtnSend
    }
  }, [_vm._v(_vm._s(_vm.$lang("Send to invite")))]), _c('pop-share', {
    attrs: {
      "show": _vm.popShareShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popShareShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head__desc"
  }, [_vm._v("Accuure coins for logging into the gome daily"), _c('br'), _vm._v(" without skipping")]);
}];
export { render, staticRenderFns };