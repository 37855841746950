var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "okxwallet page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/taskimg/image_2024-08-02_16-04-25.png",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "time-box"
  }, [_c('div', {
    staticClass: "time-box-left"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/okxwallet-live.png`),
      "alt": ""
    }
  }), _c('div', [_vm._v("Live Now")])])]), _c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "content-box-num"
  }, [_vm._v("1")]), _c('div', {
    staticClass: "content-box-title"
  }, [_vm._v(" Connect OKX wallet ")]), _c('div', {
    staticClass: "content-box-desc"
  }, [_vm._v(" Connect OKX wallet on Telegram ")]), _c('div', {
    staticClass: "content-box-reward"
  }, [_vm._v(" Reward "), _c('div', {
    staticClass: "content-box-reward-text"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" 100,000 ")])]), _c('div', {
    staticClass: "content-box-btn",
    on: {
      "click": _vm.openW
    }
  }, [_c('div', {
    staticClass: "content-box-btn-text"
  }, [_vm._v(" Connect "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/okwallet-logo.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.isOk ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "content-box-num"
  }, [_vm._v("2")]), _c('div', {
    staticClass: "content-box-title"
  }, [_vm._v(" Verify on OKX Cryptopedia ")]), _c('div', {
    staticClass: "content-box-desc"
  }, [_vm._v(" Have a chance to share a Tapcoins token pool worth 50.000 USDT. ")]), _vm._m(0), _c('div', {
    staticClass: "content-box-btn",
    on: {
      "click": _vm.jumpTo
    }
  }, [_c('div', {
    staticClass: "content-box-btn-text"
  }, [_vm._v("Verify")])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-box-reward"
  }, [_vm._v(" Reward "), _c('div', {
    staticClass: "content-box-reward-text"
  }, [_vm._v(" $50K in tokens ")])]);
}];
export { render, staticRenderFns };