import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config.json";
const squadVuex = createNamespacedHelpers("squad");
export default {
  name: "popJoin",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      squadName: ""
    };
  },
  methods: {
    ...squadVuex.mapActions(["joinSquad"]),
    onJoin() {
      this.$parent.popJoinShow = false;
      this.$parent.popSquadShow = false;
      this.WebApp.openTelegramLink(`https://t.me/${this.setting.botname}?start=joinGroup`);
      this.WebApp.close();
      // let timer = setTimeout(async() => {
      //   clearTimeout(timer);
      //   timer = null;
      //   const squadId = await this.checkSquad(squadName)
      // }, 1000);
    }
  }
};