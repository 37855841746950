import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const lotteryVuex = createNamespacedHelpers("lottery");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "lottery",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...lotteryVuex.mapState(["lotterylist"])
  },
  data() {
    return {};
  },
  beforeRouteLeave(to, from, next) {
    this.popPromptShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  mounted() {
    this.getLotteryList();
  },
  methods: {
    ...lotteryVuex.mapActions(["getLotteryList"]),
    onSkip(id) {
      this.$router.push("/lotteryDetail?id=" + id);
    }
  }
};