import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
import vailcode from "@utils/errcode";
export default {
  name: "popExtraInvite",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      loadConfig,
      extraData: null,
      isFlag: true
    };
  },
  watch: {
    "$parent.extraData": {
      async handler(newVal, oldVal) {
        this.extraData = newVal;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    ...userVuex.mapActions(["getExtraInvite"]),
    async onClaim() {
      if (!this.isFlag) return;
      this.isFlag = false;
      let rs = await this.$http.post("/user/extra/invite/claim", {
        invitCount: this.extraData.count
      });
      this.isFlag = true;
      vailcode(rs, () => {
        this.setUserData(rs.data);
        this.getExtraInvite();
        this.$parent.popExtraInviteShow = false;
      });
    }
  }
};