import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "code",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {
      popGetAwaitShow: false
    };
  },
  methods: {
    onClaim() {
      this.popGetAwaitShow = true;
    }
  }
};