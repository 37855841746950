var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "bitgetwallet page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "content-box-num"
  }, [_vm._v("1")]), _c('div', {
    staticClass: "content-box-title"
  }, [_vm._v(" Submit deposit address and UID ")]), _c('div', {
    staticClass: "content-box-desc"
  }, [_vm._v(" Tokens will be airdropped to your submitted wallet address before 14/9/2024 ")]), _c('div', {
    staticClass: "content-box-time"
  }, [_vm._v(" Deadline is September 10, 2024 (04:00 UTC) ")]), _c('div', {
    staticClass: "content-box-reward"
  }, [_vm._v(" +" + _vm._s(_vm._f("toThousands")(_vm.quota.amount || 0)) + " $GDV, 0 Gas fee ")]), _c('div', {
    staticClass: "content-box-btn",
    class: !_vm.registerTask.completed && 'dis',
    on: {
      "click": function ($event) {
        _vm.registerTask.completed && _vm.onSubmit();
      }
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.registerTask.completed == 1 ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "content-box-num"
  }, [_vm._v("2")]), _c('div', {
    staticClass: "content-box-title"
  }, [_vm._v(" Submit TON wallet address ")]), _c('div', {
    staticClass: "content-box-desc"
  }, [_vm._v(" Submit the TON wallet address to receive the airdrop and pay the 0.1TON confirmation fee ")]), _c('div', {
    staticClass: "content-box-time"
  }, [_vm._v(" Opening time is September 10, 2024 (06:00 UTC) ")]), _c('div', {
    staticClass: "content-box-reward"
  }, [_vm._v(" +" + _vm._s(_vm._f("toThousands")(_vm.quota.amount || 0)) + " $GDV ")]), _c('div', {
    staticClass: "content-box-address"
  }, [_vm.tonConnect ? [_vm._v(" TON address: " + _vm._s(_vm._f("fmt_address")(_vm.tonWallet.uiAddress, 10)) + " "), _c('span', {
    staticClass: "icon",
    on: {
      "click": _vm.onTonConnect
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-edit"
    }
  })], 1)] : _vm._e()], 2), _c('div', {
    staticClass: "content-box-btn",
    class: _vm.disabled && 'dis',
    on: {
      "click": _vm.onConfirm
    }
  }, [_c('div', {
    staticClass: "content-box-btn-text"
  }, [!_vm.tonConnect ? [_vm._v(" Connect TON address "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tron.png`),
      "alt": ""
    }
  })] : [_vm._v("Confirm")]], 2)])])]), _c('pop-bitget-uid-submit', {
    attrs: {
      "show": _vm.popBitgetUidSubmitShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBitgetUidSubmitShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-logo"
  }, [_c('img', {
    attrs: {
      "src": "https://static.goldverse.io/icons/goldverse-token.png"
    }
  })]), _c('div', {
    staticClass: "top-box-title"
  }, [_vm._v(" Airdrop ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-box-btn-text"
  }, [_vm._v(" Submit "), _c('img', {
    attrs: {
      "src": "https://static.goldverse.io/icons/bitget1.jpg",
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };