import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "bitgetWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...taskVuex.mapState(["taskList"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      timeVal: null,
      subTaskList: []
    };
  },
  beforeRouteLeave(to, from, next) {
    //this.tonWallet.disconnect();
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    this.tonWallet.disconnect();
    this.taskId = this.$route.query.id;
    await this.getSubTaskList([this.taskId, res => {
      this.subTaskList = res;
    }]);
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapMutations(["setTaskComplete"]),
    ...taskVuex.mapActions(["getTaskList", "getSubTaskList", "doSubTask"]),
    ...userVuex.mapMutations(['setUserData']),
    onSkip(item) {
      if (item.type == 'wallet') {
        if (!item.completed) this.openW(item);
        return;
      } else if (item.type == 'channel' || item.type == 'telegram') {
        this.WebApp.openTelegramLink(item.url);
      } else {
        this.WebApp.openLink(item.url);
      }
      if (!item.completed) {
        setTimeout(() => {
          this.subTaskComplete(item);
        }, 2000);
      }
    },
    openW(item) {
      if (!item.completed) {
        this.tonWallet.openOrCloseSignTonModal("bitgetTonWallet");
        this.tonWallet.ton.onStatusChange(walletAndwalletInfo => {
          console.log("bind ok");
          if (walletAndwalletInfo) {
            let address = this.tonWallet.tranAddress(walletAndwalletInfo.account.address);
            this.subTaskComplete(item, address);
            this.tonWallet.ton.disconnect();
          }
        });
      }
    },
    subTaskComplete(item, value) {
      this.doSubTask([item.id, "", value, data => {
        let newUserData = {
          ...this.userData,
          ...(data && data.userInfo ? data.userInfo : data)
        };
        item.completed = 1;
        if (item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        this.setUserData(newUserData);
        this.loadTaskCount();
      }, rs => {
        this.error = rs && rs.message;
      }]);
    }
  }
};