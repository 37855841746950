var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "lottery-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Participants')) + " "), _c('div', {
    staticClass: "lottery-num"
  }, [_vm._v(_vm._s(_vm.lotteryDetail.tickets))])]), _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lottery-icon.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "head-info"
  }, [_c('div', {
    staticClass: "date"
  }, [_vm._v(" " + _vm._s(_vm.lotteryDetail.lottery_date_format) + " ")]), _c('div', {
    staticClass: "pool-box"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Pool')) + " "), _c('div', {
    staticClass: "pool"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tron.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.lotteryDetail.pool) + " ")])])]), _vm.lotteryDetail.status == 0 ? _c('div', {
    staticClass: "head-right"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/time.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$parent.timeLabel) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "btn-box"
  }, [_c('div', {
    staticClass: "need-box"
  }, [_c('div', {
    staticClass: "need"
  }, [_vm._v(" Need "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.lotteryDetail.need)) + " ")])]), _c('div', {
    staticClass: "Remaining"
  }, [_vm._v(" Remaining "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.userData.coin)) + " ")])])]), _vm.lotteryDetail.status == 0 && _vm.lotteryDetail.has_join == 0 ? _c('div', {
    staticClass: "btn-join",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onJoin
    }
  }, [_vm._v(_vm._s(_vm.$lang("Lottery")))]) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };