import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const autobotVuex = createNamespacedHelpers("autobot");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    ...autobotVuex.mapState(["botList"]),
    getLevelPrice() {
      return formatter.getLevelPrice;
    },
    toPre() {
      return formatter.toPre;
    },
    getTypeIcon: function () {
      let icon = "";
      switch (this.type) {
        case "Turbo":
          icon = "boost-list-icon-1.png";
          break;
        case "Energy":
          icon = "boost-list-icon-2.png";
          break;
        case "Multitap":
          icon = "boost-list-icon-3.png";
          break;
        case "Battery":
          icon = "boost-list-icon-4.png";
          break;
        case "Recharge":
          icon = "boost-list-icon-5.png";
          break;
        case "Bot":
          icon = "boost-list-icon-6.png";
          break;
      }
      return icon;
    },
    getTypeTitle: function () {
      let tit = "";
      switch (this.type) {
        case "Turbo":
          tit = "Turbo";
          break;
        case "Energy":
          tit = "Energy";
          break;
        case "Multitap":
          tit = this.$lang("Multitap");
          break;
        case "Battery":
          tit = this.$lang("Battery Pack");
          break;
        case "Recharge":
          tit = this.$lang("Recharge Speed");
          break;
        case "Bot":
          tit = this.$lang("Auto Bot");
          break;
      }
      return tit;
    },
    getTypeField: function () {
      let field = "";
      switch (this.type) {
        case "Multitap":
          field = "multi_tap_level";
          break;
        case "Battery":
          field = "power_level";
          break;
        case "Recharge":
          field = "recharge_speed_level";
          break;
        case "Bot":
          field = "";
          break;
      }
      return field;
    }
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    type: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      isFlag: true
    };
  },
  methods: {
    ...userVuex.mapActions(["getUserData"]),
    ...userVuex.mapMutations(["setUserData"]),
    async onSubmit() {
      if (!this.isFlag) return;
      this.isFlag = false;
      let url = "/my/skill/upgrade";
      let params = {};
      switch (this.type) {
        case "Turbo":
          url = "/coin/turbo";
          break;
        case "Energy":
          url = "/coin/refill";
          break;
        case "Multitap":
          params.target = "multi_tap";
          break;
        case "Battery":
          params.target = "power";
          break;
        case "Recharge":
          params.target = "recharge_speed";
          break;
        case "Bot":
          url = "/bot/update";
          params.level = this.userData.bot_level + 1;
          break;
      }
      let rs = await this.$http.post(url, params);
      this.isFlag = true;
      vailcode(rs, () => {
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.$toasted.success(rs.message);
        // if (this.type == "Battery" || this.type == "Recharge" || this.type == "Multitap") {
        //   // this.$parent.popEnergyShow = false;
        // }
        this.$parent.popEnergyShow = false;
        this.$nextTick(() => {
          if (this.type == "Turbo" || this.type == "Energy") {
            this.$router.push(`/`);
          }
        });
      });
    }
  }
};